import './modal.css';
import { FiX } from 'react-icons/fi';

export default function Detalhes({ conteudo, close }) {
  return (
    <div className="modal">
      <div className="tela-dados">
        <button className="close" onClick={close}>
          <FiX size={23} color="#FFF" />
        </button>
        <div className="row">
          <span className='detalhes'><strong>DETALHES</strong></span>
        </div>
        <div className="row">
          <span><p>{conteudo.descricao}</p></span>
        </div>
        {/* <div className="row">
          <img src={conteudo.imagem} width={350} />
        </div> */}
        <div className="row">
          <span className='botao-card'>
            <a href={conteudo.link}>EU QUERO</a>
          </span>
        </div>

      </div>
    </div>
  );
}
