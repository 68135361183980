import "./styles.css";
import ImgSobre from "../../assets/images/perfilsobre.jpeg";

function PaginaVendas() {
  return (
    <>
      <div>
        <div className="sobre">
              <hr className="linha" />
              <h2>DESCUBRA SUA COLORAÇAO DIGITAL EM UMA</h2>
              <img className="imgmay" src={ImgSobre} alt="Foto sobre Mayy" />
              <hr className="linha" />
            </div>
          </div>
    </>
  );
}

export default PaginaVendas;
