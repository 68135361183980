import './ebooks.css'; 
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Collapse } from '@material-ui/core';
import { Instagram, LinkedIn, Facebook, Email, Call } from '@material-ui/icons';

import Home from '../Home';
import Estilo from '../../pages/Ebooks/img/estilo.jpeg'
import Sub from '../../pages/Ebooks/img/sub.jpeg'
import Closet from '../../pages/Ebooks/img/closet.jpeg'
import Vestuario from '../../pages/Ebooks/img/vestuario.jpeg'
import Cores from '../../pages/Ebooks/img/cores.jpeg'
import Mala from '../../pages/Ebooks/img/mala.jpeg'
import Mensagens from '../../common/mensagens';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '35vh',
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  appbar: {
    height: 90,
    background: '#FFF9EC',
    '@media(max-Width: 600px)': {
      height: 80,
    },
  },
  appbarWrapper: {
    //width: '100%',
    margin: 'auto',
    '@media(max-Width: 600px)': {
      width: '80%',
      margin: '0 -10px auto',
    },
    '@media(max-Width: 400px)': {
      margin: '0 -10px auto',
      padding: '0.1rem',
    }
  },
  appbarTitle: {
    flexGrow: '1',
    padding: '15px',
    color: '#FFF9EC',
    cursor: 'pointer',
    '@media(max-Width: 600px)': {
      display: 'none',
    }
  },
  title2: {
    flexGrow: '1',
    color: '#FFF9EC',
  },
  icon: {
    color: 'var(--color-padrao)',
    fontSize: '3rem',
    cursor: 'pointer'
  },
  colorText: {
    color: 'var(--color-padrao)',
  },
  container: {
    textAlign: 'center',
  },
  titleHome: {
    color: 'var(--color-padrao)',
    fontSize: '7rem',
    textShadow: '#000 -1px -1px',
    '@media(max-Width: 600px)': {
      fontSize: '4rem'
    }
  },
  menubar: {
    color: 'var(--color-padrao)',
    fontSize: '1.6rem',
    backgroundColor: '#FFF',
    borderRadius: '5px',
    padding: '3px'
  },
  goDown: {
    color: 'var(--color-padrao)',
    fontSize: '3.5rem',
    backgroundColor: '#FFF',
    borderRadius: '50px'
  }
}));
export default function Header() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(true);
  }, []);

  return (
    <>
      <Home />
      <div className={classes.root} id="header">
        <Collapse
          in={checked}
          {...(checked ? { timeout: 1000 } : {})}
          collapsedHeight={100}
        >
          <div className={classes.container}>
            <h1 className={classes.titleHome}>
              E-books
            </h1>
          </div>
        </Collapse>
        <Mensagens />
      </div>
      <div>
        <div className="row-card-ebooks">
          <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
            collapsedHeight={60}
          >
            <div className="card-ebooks padrao">
              <img className="image-logo-ebooks" src={Sub} alt="E-books sobre Sub Estilos" />
              <h2>Sub Estilos e suas referências</h2>
              <p>Você irá descobrir de forma dinâmica e cheia de referências</p>

              <div className='de'><s>De R$ 37,90</s></div>
              <div className='price'><span className="parcela"> Por 4x R$</span><span className="valor">5</span><span className="centavos">,34</span></div>
              <div className='por'>Ou apenas <b>&nbsp;R$ 19,90 à vista</b></div>

              <a href='https://sun.eduzz.com/1527597' className='button'>ADQUIRIR</a>
            </div>
          </Collapse>
          <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
            collapsedHeight={60}
          >
            <div className="card-ebooks padrao">
              <img className="image-logo-ebooks" src={Estilo} alt="E-book sobre Estilo" />
              <h2>Um passo a passo para você descobrir seu Estilo</h2>
              <p>Nesse guia prático trago para você uma consultoria de como descobrir o seu estilo</p>

              <div className='de'><s>De R$ 47,90</s></div>
              <div className='price'><span className="parcela"> Por 6x R$</span><span className="valor">5</span><span className="centavos">,14</span></div>
              <div className='por'>Ou apenas <b>&nbsp;R$ 27,90 à vista</b></div>

              <a href='https://sun.eduzz.com/1525337' className='button'>ADQUIRIR</a>
            </div>
          </Collapse>
          <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
            collapsedHeight={60}
          >
            <div className="card-ebooks padrao">
              <img className="image-logo-ebooks" src={Closet} alt="E-books sobre Revitalização do Closet" />
              <h2>Revitalização do Closet</h2>
              <p>Trago uma consultoria de como ter mais intimidade com seu closet, e estratégias de como aproveitá-lo de forma inteligente</p>

              <div className='de'><s>De R$ 38,90</s></div>
              <div className='price'><span className="parcela"> Por 5x R$</span><span className="valor">5</span><span className="centavos">,44</span></div>
              <div className='por'>Ou apenas <b>&nbsp;R$ 24,97 à vista</b></div>

              <a href='https://sun.eduzz.com/1531513' className='button'>ADQUIRIR</a>
            </div>
          </Collapse>
          <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
            collapsedHeight={60}
          >
            <div className="card-ebooks padrao">
              <img className="image-logo-ebooks" src={Vestuario} alt="E-books sobre vestuários" />
              <h2>Vestuário para toda família</h2>
              <p>Este guia lhe dará ótimas dicas de moda para todas as ocasiões.</p>

              <div className='price'><span className="parcela"> Por 3x R$</span><span className="valor">5</span><span className="centavos">,28</span></div>
              <div className='de'><s>De R$ 27,90</s></div>
              <div className='por'>Ou apenas <b>&nbsp;R$ 14,97 à vista</b></div>

              <a href='https://sun.eduzz.com/1537335' className='button'>ADQUIRIR</a>
            </div>
          </Collapse>
          <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
            collapsedHeight={60}
          >
            <div className="card-ebooks padrao">
              <img className="image-logo-ebooks" src={Cores} alt="E-books sobre cores" />
              <h2>Cores em Você</h2>
              <p>Nesse e-book apresento diversas formas, exemplos e dicas de como usar as cores em você, montar looks incríveis acrescentando cores no seu dia a dia.</p>

              <div className='price'><span className="parcela"> Por 4x R$</span><span className="valor">5</span><span className="centavos">,34</span></div>
              <div className='de'><s>De R$ 47,90</s></div>
              <div className='por'>Ou apenas <b>&nbsp;R$ 19,90 à vista</b></div>

              <a href='https://sun.eduzz.com/1586236' className='button'>ADQUIRIR</a>
            </div>
          </Collapse>
          <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
            collapsedHeight={60}
          >
            <div className="card-ebooks padrao">
              <img className="image-logo-ebooks" src={Mala} alt="E-books sobre Mala Inteligente" />
              <h2>Mala Estratégica</h2>
              <p>Um guia completo com o passo a passo para você organizar sua mala de viagem de forma inteligente e criativa sem esquecer nada.</p>

              <div className='price'><span className="parcela"> Por 2x R$</span><span className="valor">5</span><span className="centavos">,17</span></div>
              <div className='de'><s>De R$ 37,90</s></div>
              <div className='por'>Ou apenas <b>&nbsp;R$ 9,90 à vista</b></div>

              <a href='https://sun.eduzz.com/1593705' className='button'>ADQUIRIR</a>
            </div>
          </Collapse>
        </div>
      </div>
      <div>
      </div>
      <a className="whatsapp-link pulse" href="https://api.whatsapp.com/send?phone=5561998894670&text=Olá estilosa, seja muito bem vinda...🤩✨ Me conte, no que posso te ajudar?.." target="_blank" rel="noreferrer">
        <i className="fa fa-whatsapp"></i>
      </a>
      <div className="footer" id='contato'>
        <div className="footer-menu"><h2>Redes sociais / Contatos</h2>
          <div>
            <IconButton>
              <a href='https://www.instagram.com/mayy.stylist/' title='Instagram' target="_blank" rel="noreferrer"><Instagram className={classes.icon} /></a>
            </IconButton>
            <IconButton>
              <a href='https://m.facebook.com/mayy.stylist' title='Facebook' target="_blank" rel="noreferrer"><Facebook className={classes.icon} /></a>
            </IconButton>
            <IconButton>
              <a href='https://www.linkedin.com/in/mayystylist/' title='Linkedin' target="_blank" rel="noreferrer"><LinkedIn className={classes.icon} /></a>
            </IconButton>
            <IconButton>
              <a href='mailto:mayystylist@gmail.com' title='E-mail' target="_blank" rel="noreferrer"><Email className={classes.icon} /></a>
            </IconButton>
            <IconButton>
              <a href="https://api.whatsapp.com/send?phone=5561998894670&text=Olá estilosa, seja muito bem vinda...🤩✨ Me conte, no que posso te ajudar?.." title='Whatsapp' target="_blank" rel="noreferrer"><Call className={classes.icon} /></a>
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
}
