import React, { useState, useEffect } from 'react';
import Header from '../../components/Header'
import Title from '../../components/Title'
import { FiMessageSquare, FiSearch, FiLayers } from 'react-icons/fi';
import firebase from '../../services/firebaseConnection'
import { toast } from 'react-toastify';
import { format } from 'date-fns'
import Modal from '../../components/ModalAgenda';
import './relatorio.css'
import Loader from "react-loader-spinner";

const listRef = firebase.firestore().collection('agendamentos').where('status', '==', 'Finalizado');

function Manutencao() {

  const [chamados, setChamados] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [lastDocs, setLastDocs] = useState()

  const [showPostModal, setShowPostModal] = useState(false)
  const [detail, setDetail] = useState()


  useEffect(() => {
    async function loadChamados() {

      await listRef.limit(5).get()
        .then((snapshot) => {
          updateState(snapshot)

        })
        .catch(() => {
          toast.error('Ops ocorreu algum erro')
          setLoadingMore(false)
        })

      setLoading(false)
    }

    loadChamados();

    return () => {

    }
  }, [])

  async function updateState(snapshot) {

    const isCollectionEmpyty = snapshot.size === 0;

    if (!isCollectionEmpyty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nome: doc.data().nome,
          email: doc.data().email,
          telefone: doc.data().telefone,
          mensagem: doc.data().mensagem,
          dataCadastro: doc.data().dataCadastro,
          valor: doc.data().valor,
          dataFormatada: format(doc.data().dataCadastro.toDate(), 'dd/MM/yyyy'),
          tipoEncontro: doc.data().tipoEncontro,
          status: doc.data().status,
        })
      })

      const lastDoc = snapshot.docs[snapshot.docs.length - 1];  //ultimo documento da lista
      setChamados(chamados => [...chamados, ...lista])
      setLastDocs(lastDoc)
    } else {
      setIsEmpty(true)
    }

    setLoadingMore(false)
  }

  async function buscarMais() {
    setLoadingMore(true)
    await listRef.startAfter(lastDocs).limit(5).get()
      .then((snapshot) => {
        updateState(snapshot)
      })
  }

  function carregaModal(item) {
    //Chama modal - Trocando true - false
    setShowPostModal(!showPostModal)

    setDetail(item)
  }

  if (loading) {
    return (
      <div>
        <Header />
        <div className="content">
          <Title name="Manutenção">
            <FiMessageSquare size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando...</span>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Manutenção">
          <FiLayers size={25} />
        </Title>

        {chamados.length === 0 ? (
          <div className="container dashboard">
            <span>Nenhum dado registrado</span>
          </div>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col">Cliente</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">Encotro</th>
                  <th scope="col">Valor</th>
                  <th scope="col">Cadastrado em</th>
                  <th scope="col">Status</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {chamados.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Cliente">{item.nome}</td>
                      <td data-label="E-mail">{item.email}</td>
                      <td data-label="Telefone">{item.telefone}</td>
                      <td data-label="Encontro">
                        <span className="badge" style={
                          { backgroundColor: item.tipoEncontro === 'Presencial' ? '#5cb85c' : item.tipoEncontro === 'On-Line' ? '#774dd6' : '#999' }
                        }>{item.tipoEncontro}</span>
                      </td>
                      <td data-label="Valor">{item.valor}</td>
                      <td data-label="Cadastrado">{item.dataFormatada}</td>
                      <td data-label="Status">
                        <span className="badge" style={
                          { backgroundColor: item.status === 'Em andamento' ? '#3153e9' : item.status === 'Finalizado' ? '#5cb85c' : '#999' }
                        }>{item.status}</span>
                      </td>
                      <td data-label="#">
                        <button className="action" style={{ backgroundColor: '#3583f3' }}>
                          <FiSearch color="#FFF" size={17} onClick={() => carregaModal(item)} />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {loadingMore && <h3 style={{ textAlign: 'center', marginTop: 15 }}><Loader
              type="Puff"
              color="#e83e8c"
              height={80}
              width={80}
              timeout={3000} //3 secs
            /></h3>}
            {!loadingMore && !isEmpty &&
              <button className="btn-more" onClick={buscarMais} >Buscar mais</button>}
          </>
        )}
      </div>
      {showPostModal && (
        <Modal
          conteudo={detail}
          close={carregaModal}
        />
      )}
    </div>
  );
}

export default Manutencao;
