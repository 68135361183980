export const RESPOSTA_PERGUNTA01 = [
  "A) Reforçar autoestima",
  "B) Melhorar a imagem profissional",
  "C) Buscar maior autoconfiança",
  "D) Mudança de estilo",
  "E) Mudança de corte/cor de cabelo",
  "F) Outros"
];

export const RESPOSTA_PERGUNTA03 = [
  "A) Satisfeito(a)",
  "B) Insatisfeito(a)",
  "C) Indiferente",
  "D) Quero melhora"
];

export const RESPOSTA_PERGUNTA06 = [
  "A) Liso",
  "B) Fino",
  "C) Médio",
  "D) Grosso",
  "E) Ondulado",
  "F) Cacheado",
  "G) Crespo "
];

export const RESPOSTA_PERGUNTA08 = [
  "A) Listras coloridas e xadrezes",
  "B) Listras de duas cores",
  "C) Jacquard",
  "D) Florais",
  "E) Estampas de animais",
  "F) Estampas étnicas",
  "G) Estampas geométricas"
];

export const RESPOSTA_PERGUNTA09 = [
  "A) ----------",
  "B) Retas e não reveladoras",
  "C) Com caimento perfeito e suavemente estruturado",
  "D) Fluidas e com linhas suaves",
  "E) Justas, moldando o corpo",
  "F) Desestruturadas, com sobreposição de peças",
  "G) Estruturadas e controladas"
];

export const RESPOSTA_PERGUNTA12 = [
  "A) Profissional",
  "B) Relacionamento",
  "C) Autoestima ",
  "D) Transição de Carreira ",
  "E) Pessoal ",
  "F) Outro",
];
