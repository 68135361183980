import "./styles.css";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import warningIcon from "../../../../assets/icons/warning.svg";

import firebase from "../../../../services/firebaseConnection";
import Mensagens from "../../../../common/mensagens";
import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";
import {
  RESPOSTA_PERGUNTA01,
  RESPOSTA_PERGUNTA03,
  RESPOSTA_PERGUNTA06,
  RESPOSTA_PERGUNTA09,
  RESPOSTA_PERGUNTA12,
} from "../respostas";

function FormVisagismo() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      height: "20vh",
      fontFamily: "Arial, Helvetica, sans-serif",
    },
    appbar: {
      background: "none",
    },
    appbarWrapper: {
      width: "75%",
      margin: "0 auto",
    },
    appbarTitle: {
      flexGrow: "1",
      color: "#FFF9EC",
      cursor: "pointer",
    },
    icon: {
      color: "var(--color-padrao)",
      fontSize: "2.5rem",
      cursor: "pointer",
    },
    homes: {
      minHeight: "90vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    title2: {
      flexGrow: "1",
      color: "#FFF9EC",
      textShadow: "#000 -1px -1px",
    },
    colorText: {
      color: "var(--color-padrao)",
    },
    container: {
      textAlign: "center",
    },
    titleHome: {
      color: "var(--color-padrao)",
      fontSize: "6rem",
      textShadow: "#000 -1px -1px",
      "@media(max-Width: 600px)": {
        fontSize: "4rem",
      },
    },
    goDown: {
      color: "var(--color-padrao)",
      fontSize: "3.5rem",
      backgroundColor: "#FFF",
      borderRadius: "50px",
    },
    home: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    ahome: {
      display: "block",
      marginBottom: "5px",
      color: "var(--color-padrao)",
      fontSize: "40px",
      textDecoration: "none",
      font: "Arial, Helvetica, sans-serif",
    },
  }));

  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [idade, setIdade] = useState("");
  const [redesocial, setRedesocial] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [resposta01, setResposta01] = useState("");
  const [resposta02, setResposta02] = useState("");
  const [resposta03, setResposta03] = useState("");
  const [resposta04, setResposta04] = useState("");
  const [resposta05, setResposta05] = useState("");
  const [resposta06, setResposta06] = useState("");
  const [resposta07, setResposta07] = useState("");
  const [resposta08, setResposta08] = useState("");
  const [resposta09, setResposta09] = useState("");
  const [resposta10, setResposta10] = useState("");
  const [resposta11, setResposta11] = useState("");
  const [resposta12, setResposta12] = useState("");
  const [resposta13, setResposta13] = useState("");
  const [resposta14, setResposta14] = useState("");
  const [resposta15, setResposta15] = useState("");
  const [resposta16, setResposta16] = useState("");
  const [obsPergunta6, setObsPergunta6] = useState("");
  const [outrosPergunta1, setOutrosPergunta1] = useState("");
  const [outrosPergunta12, setOutrosPergunta12] = useState("");

  const pergunta1 = "1. O que te motivou a procurar uma Consultoria Visagista?";
  const pergunta2 =
    "2. Quais são seus principais objetivos com a Consultoria Visagista?";
  const pergunta3 = "3. Como você se sente em relação à sua imagem atual?";
  const pergunta4 = "4. Fez alguma Consultoria Visagista antes? Quando?";
  const pergunta5 =
    "5. Já fez a alguma mudança nas cores de cabelo, sobrancelhas, micro pigmentação, bronzeamento, botox ou outros procedimentos estéticos, e há quanto tempo? Descreva:";
  const pergunta6 = "6. Qual a estrutura do seu cabelo?";
  const pergunta7 =
    "7. Já fez sua análise de Coloração Pessoal? Se sim, qual é a sua cartela de cores?";
  const pergunta8 = "8. Qual a cor natural do seu cabelo?";
  const pergunta9 =
    "9. Como você definiria seu estilo atual do seu cabbelo? (marcar até 3 opções) ";
  const pergunta10 =
    "10. Tem algo que te incomoda no seu rosto ou no seu cabelo? ";
  const pergunta11 =
    "11. Tem preferência por cortes mais curtos, médios ou longos?";
  const pergunta12 =
    "12. Para qual ambiente atualmente sua imagem é mais importante?";
  const pergunta13 = "13. Qual imagem gostaria de transmitir nessa nova fase? ";
  const pergunta14 = "14. Qual mudança jamais faria no seu cabelo?";
  const pergunta15 = "15. O que você mais gosta no seu cabelo?";
  const pergunta16 =
    "16. Alguma figura pública que seja referência ou inspiração de imagem pessoal para você? Porquê?";

  const obs =
    "Obs.: Este é um assunto entre Consultora e Cliente! Tenha certeza de que suas informações são confidenciais e não serão compartilhadas.";

  const handleChange1 = (event) => {
    setResposta01(event.target.value);
  };
  const handleChange3 = (event) => {
    setResposta03(event.target.value);
  };
  const handleChange6 = (event) => {
    setResposta06(event.target.value);
  };
  const handleChange9 = (event) => {
    setResposta09(event.target.value);
  };
  const handleChange12 = (event) => {
    setResposta12(event.target.value);
  };

  const Pergunta01 = () => {
    return (
      <div>
        <div>
          <span>
            <b>{pergunta1}</b>
          </span>
        </div>
        {RESPOSTA_PERGUNTA01.map((opcao, index) => (
          <div style={{ display: "flex", marginTop: -5 }}>
            <label key={index} style={{ marginLeft: 10 }}>
              <input
                type="radio"
                value={opcao}
                checked={resposta01 === opcao}
                onChange={handleChange1}
                style={{ marginRight: 10, fontSize: 50 }}
              />
              {opcao}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const Pergunta03 = () => {
    return (
      <div>
        <div>
          <span>
            <b>{pergunta3}</b>
          </span>
        </div>
        {RESPOSTA_PERGUNTA03.map((opcao, index) => (
          <div style={{ display: "flex", marginTop: -5 }}>
            <label key={index} style={{ marginLeft: 10 }}>
              <input
                type="radio"
                value={opcao}
                checked={resposta03 === opcao}
                onChange={handleChange3}
                style={{ marginRight: 10, fontSize: 50 }}
              />
              {opcao}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const Pergunta06 = () => {
    return (
      <div>
        <div>
          <span>
            <b>{pergunta6}</b>
          </span>
        </div>
        {RESPOSTA_PERGUNTA06.map((opcao, index) => (
          <div style={{ display: "flex", marginTop: -5 }}>
            <label key={index} style={{ marginLeft: 10 }}>
              <input
                type="radio"
                value={opcao}
                checked={resposta06 === opcao}
                onChange={handleChange6}
                style={{ marginRight: 10, fontSize: 50 }}
              />
              {opcao}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const Pergunta09 = () => {
    return (
      <div>
        <div>
          <span>
            <b>{pergunta9}</b>
          </span>
        </div>
        {RESPOSTA_PERGUNTA09.map((opcao, index) => (
          <div style={{ display: "flex", marginTop: -5 }}>
            <label key={index} style={{ marginLeft: 10 }}>
              <input
                type="radio"
                value={opcao}
                checked={resposta09 === opcao}
                onChange={handleChange9}
                style={{ marginRight: 10, fontSize: 50 }}
              />
              {opcao}
            </label>
          </div>
        ))}
      </div>
    );
  };

  const Pergunta12 = () => {
    return (
      <div>
        <div>
          <span>
            <b>{pergunta12}</b>
          </span>
        </div>
        {RESPOSTA_PERGUNTA12.map((opcao, index) => (
          <div style={{ display: "flex", marginTop: -5 }}>
            <label key={index} style={{ marginLeft: 10 }}>
              <input
                type="radio"
                value={opcao}
                checked={resposta12 === opcao}
                onChange={handleChange12}
                style={{ marginRight: 10, fontSize: 50 }}
              />
              {opcao}
            </label>
          </div>
        ))}
      </div>
    );
  };

  function cadastrar(e) {
    e.preventDefault();

    if (
      nome === "" ||
      email === "" ||
      telefone === "" ||
      idade === "" ||
      nascimento === "" ||
      redesocial === ""
    ) {
      toastr.warning("Verifique", "Existe campos ainda não preenchido!");
      return;
    }

    firebase
      .firestore()
      .collection("questionariovisagismo")
      .add({
        nome: nome,
        email: email,
        telefone: telefone,
        idade: idade,
        nascimento: nascimento,
        redesocial: redesocial,
        resposta01:
          pergunta1 +
          " Resposta: " +
          resposta01 +
          " - Outros: " +
          outrosPergunta1,
        resposta02: pergunta2 + " Resposta: " + resposta02,
        resposta03: pergunta3 + " Resposta: " + resposta03,
        resposta04: pergunta4 + " Resposta: " + resposta04,
        resposta05: pergunta5 + " Resposta: " + resposta05,
        resposta06:
          pergunta6 +
          " Resposta: " +
          resposta06 +
          " - Observação: " +
          obsPergunta6,
        resposta07: pergunta7 + " Resposta: " + resposta07,
        resposta08: pergunta8 + " Resposta: " + resposta08,
        resposta09: pergunta9 + " Resposta: " + resposta09,
        resposta10: pergunta10 + " Resposta: " + resposta10,
        resposta11: pergunta11 + " Resposta: " + resposta11,
        resposta12:
          pergunta12 +
          " Resposta: " +
          resposta12 +
          " - Observação: " +
          outrosPergunta12,
        resposta13: pergunta13 + " Resposta: " + resposta13,
        resposta14: pergunta14 + " Resposta: " + resposta14,
        resposta15: pergunta15 + " Resposta: " + resposta15,
        resposta16: pergunta16 + " Resposta: " + resposta16,
        dataCadastro: new Date(),
      })
      .then(() => {
        setNome("");
        setEmail("");
        setTelefone("");
        setIdade("");
        setNascimento("");
        setRedesocial("");
        setResposta01("");
        setResposta02("");
        setResposta03("");
        setResposta04("");
        setResposta05("");
        setResposta06("");
        setResposta07("");
        setResposta08("");
        setResposta09("");
        setResposta10("");
        setResposta11("");
        setResposta12("");
        setResposta13("");
        setResposta14("");
        setResposta15("");
        setResposta16("");
        setOutrosPergunta1("");
        setObsPergunta6("");
        setOutrosPergunta12("");
        toastr.success(
          "Sucesso",
          "Questionário de Visagismo enviado com Sucesso. Aguarde seu Questionário de Visagismo!"
        );
      })
      .catch(() => {
        toastr.error("Erro", "Ocorreu um erro, tente novamente!");
      });
  }

  const classes = useStyles();
  const [checked] = useState(false);

  return (
    <>
      <div className={classes.root} id="header">
        <Collapse
          in={checked}
          {...(checked ? { timeout: 1000 } : {})}
          collapsedHeight={50}
        >
          <div className={classes.container}>
            <h1 className={classes.titleHome}>
              Formumlário <span className={classes.title2}> de </span>{" "}
              <span className={classes.colorText}>Visagismo</span>
              <hr className="linha" />
            </h1>
          </div>
        </Collapse>
      </div>
      <div id="page-visagismo-form" className="container">
        <main className="padrao">
          <form onSubmit={cadastrar}>
            <fieldset>
              <div className="row">
                <legend>Identificação</legend>
              </div>
              <div className="cabecalho">
                <Input
                  name="nome"
                  label="Seu nome completo *"
                  value={nome}
                  onChange={(e) => {
                    setNome(e.target.value);
                  }}
                />
                <Input
                  name="email"
                  type="email"
                  label="E-mail *"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="cabecalho">
                <Input
                  name="telefone"
                  label="WhatsApp/Telefone"
                  placeholder="(00)00000-0000"
                  value={telefone}
                  onChange={(e) => {
                    setTelefone(e.target.value);
                  }}
                  />

                <Input
                  name="redesocial"
                  placeholder="meu_instagram"
                  label="Seu Instagram *"
                  value={redesocial}
                  onChange={(e) => {
                    setRedesocial(e.target.value);
                  }}
                />

                <Input
                  name="nascimento"
                  label="Dia/Mês nascimento *"
                  placeholder="__/__"
                  value={nascimento}
                  onChange={(e) => {
                    setNascimento(e.target.value);
                  }}
                />
                <Input
                  name="idade"
                  label="Idade *"
                  placeholder="__"
                  value={idade}
                  onChange={(e) => {
                    setIdade(e.target.value);
                  }}
                />
              </div>
              <div className="row" style={{ marginTop: 15 }}>
                <legend>
                  Com objetividade responda o quiz de Visagismo abaixo
                </legend>
              </div>
              <div className="row">
                <span
                  style={{
                    color: "#FFF",
                    backgroundColor: "var(--color-padrao)",
                    fontSize: 12,
                    padding: 5,
                    borderRadius: 4,
                    textAlign: "center",
                  }}
                >
                  {obs}
                </span>
              </div>
              <div style={{ marginBottom: 15 }}>
                <Pergunta01 />
                <div>
                  <Input
                    placeholder="Se quiser complementar sua resposta. Descreva aqui..."
                    name="outrosPergunta1"
                    value={outrosPergunta1}
                    onChange={(e) => {
                      setOutrosPergunta1(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta2}</b>
                </span>
                <Textarea
                  name="resposta02"
                  value={resposta02}
                  onChange={(e) => {
                    setResposta02(e.target.value);
                  }}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <Pergunta03 />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta4}</b>
                </span>
                <Input
                  name="resposta04"
                  value={resposta04}
                  onChange={(e) => {
                    setResposta04(e.target.value);
                  }}
                />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta5}</b>
                </span>
                <Textarea
                  name="resposta05"
                  value={resposta05}
                  onChange={(e) => {
                    setResposta05(e.target.value);
                  }}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <Pergunta06 />
                <div>
                  <Input
                    placeholder="Se quiser complementar sua resposta. Descreva aqui..."
                    name="obsPergunta6"
                    value={obsPergunta6}
                    onChange={(e) => {
                      setObsPergunta6(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta7}</b>
                </span>
                <Input
                  name="resposta07"
                  value={resposta07}
                  onChange={(e) => {
                    setResposta07(e.target.value);
                  }}
                />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta8}</b>
                </span>
                <Input
                  name="resposta08"
                  value={resposta08}
                  onChange={(e) => {
                    setResposta08(e.target.value);
                  }}
                />
              </div>
              {/* PERGUNTA 09 FAZER */}
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta10}</b>
                </span>
                <Input
                  name="resposta10"
                  value={resposta10}
                  onChange={(e) => {
                    setResposta10(e.target.value);
                  }}
                />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta11}</b>
                </span>
                <Input
                  name="resposta11"
                  value={resposta11}
                  onChange={(e) => {
                    setResposta11(e.target.value);
                  }}
                />
              </div>
              <div style={{ marginBottom: 15 }}>
                <Pergunta12 />
                <div>
                  <Input
                    placeholder="Se quiser complementar sua resposta. Descreva aqui..."
                    name="outrosPergunta12"
                    value={outrosPergunta12}
                    onChange={(e) => {
                      setOutrosPergunta12(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta13}</b>
                </span>
                <Input
                  name="resposta13"
                  value={resposta13}
                  onChange={(e) => {
                    setResposta13(e.target.value);
                  }}
                />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta14}</b>
                </span>
                <Input
                  name="resposta14"
                  value={resposta14}
                  onChange={(e) => {
                    setResposta14(e.target.value);
                  }}
                />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta15}</b>
                </span>
                <Input
                  name="resposta15"
                  value={resposta15}
                  onChange={(e) => {
                    setResposta15(e.target.value);
                  }}
                />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta16}</b>
                </span>
                <Textarea
                  name="resposta16"
                  value={resposta16}
                  onChange={(e) => {
                    setResposta16(e.target.value);
                  }}
                />
              </div>
            </fieldset>
            <footer>
              <p>
                <img src={warningIcon} alt="Aviso importante" />
                Importante! <br />
                Preencha todos os campos obrigatórios (*)
              </p>
              <button type="submit">Enviar</button>
            </footer>
          </form>
          <Mensagens />
        </main>
      </div>
    </>
  );
}

export default FormVisagismo;
