import './modal.css';
import React from 'react';
import { FiMail, FiX } from 'react-icons/fi';

export default function ModalAgenda({ conteudo, close }) {
  return (
    <div className="modal">
      <div className="container">
        <button className="close" onClick={close}>
          <FiX size={23} color="#FFF" />
        </button>
        <div>
          <h2>Detalhes do agendamento</h2>
          <div className="row">
            <span>Nome: <i>{conteudo.nome}</i></span>
            <span>Tefefone: <i>{conteudo.telefone}</i></span>
          </div>
          <div className="row">
            <span>E-mail: <i>{conteudo.email}</i></span>
          </div>
          <div className="row">
            <span>Tipo de encontro: <i style={{ color: '#FFF', backgroundColor: conteudo.tipoEncontro === 'Presencial' ? '#5cb85c' : conteudo.tipoEncontro === 'On-Line' ? '#774dd6' : '#999' }}>{conteudo.tipoEncontro}</i></span>
            <span>Data cadastro: <i>{conteudo.dataFormatada}</i></span>
          </div>
          <div className="row">
            <span>Mensagem: <i>{conteudo.mensagem}</i></span>
          </div>

        </div>
      </div>
    </div>
  );
}
