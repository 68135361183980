import { Switch } from 'react-router-dom'
import Route from './Route'

import '../assets/styles/global.css'
import Login from '../pages/Login'

import Acompanhamento from '../pages/Acompanhamento'
import Agendamento from '../pages/Cadastro'
import AlteraCadastro from '../pages/AlteraCadastro'
import AlteraAcompanhamento from '../pages/AlteraAcompanhamento'
import EditarEmail from '../pages/GerenciarEmails/AlterarEmail'
import GerenciarEmail from '../pages/GerenciarEmails'
import Landing from '../pages/Landing/Home'
import Home from '../pages/Home'
import Ebooks from '../pages/Ebooks'
import ListaEmail from '../pages/GerenciarEmails/ListaEmail'
import Manutencao from '../pages/Manutencao'
import New from '../pages/New'
import Profile from '../pages/Profile'
import PreCadastro from '../pages/PreCadastro'
import Relatorios from '../pages/Relatorios'
import Register from '../pages/Register'
import Sobre from '../pages/Sobre'
import Loja from '../pages/Loja'
import Detalhes from '../pages/Loja/Detalhes'
import PaginaVendas from '../pages/PaginaVendas'
import AlterarEstilo from '../pages/Formularios/Estilo/AlterarEstilo'
import FormEstilo from '../pages/Formularios/Estilo/FormEstilo'
import ListaEstilo from '../pages/Formularios/Estilo/ListaEstilo'
import ListaCores from '../pages/Formularios/Cores/ListaCores'
import AlterarCores from '../pages/Formularios/Cores/AlterarCores'
import FormCores from '../pages/Formularios/Cores/FormCores'
import FormBiotipo from '../pages/Formularios/Biotipo/FormBiotipo'
import ListaBiotipo from '../pages/Formularios/Biotipo/ListaBiotipo'
import AlterarBiotipo from '../pages/Formularios/Biotipo/AlterarBiotipo'
import ListaVisagismo from '../pages/Formularios/Visagismo/ListaVisagismo'
import AlterarVisagismo from '../pages/Formularios/Visagismo/AlterarVisagismo'
import FormVisagismo from '../pages/Formularios/Visagismo/FormVisagismo'

export default function Routes(){
   return (
      <Switch>
         <Route exact path="/adm" component={Login}/>

         <Route exact path="/" component={Landing}/>
         <Route exact path="/home" component={Home}/>
         <Route exact path="/ebooks" component={Ebooks}/>
         <Route exact path="/agendamento" component={Agendamento}/>
         <Route exact path="/sobre" component={Sobre}/>
         <Route exact path="/loja-virtual" component={Loja}/>
         <Route exact path="/detalhe" component={Detalhes}/>
         <Route exact path="/registrar" component={Register}/>
         <Route exact path="/coloracao-digital-em-uma-hora" component={PaginaVendas}/>
         <Route exact path="/questionario-de-estilo" component={FormEstilo}/>
         <Route exact path="/questionario-de-cores" component={FormCores}/>
         <Route exact path="/questionario-de-biotipo" component={FormBiotipo}/>
         <Route exact path="/questionario-de-visagismo" component={FormVisagismo}/>

         <Route exact path="/pre-cadastro" component={PreCadastro} isPrivate/>
         <Route exact path="/editar-cadastro/:id" component={AlteraCadastro} isPrivate />

         <Route exact path="/lista-emails" component={ListaEmail} isPrivate/>
         <Route exact path="/editar-email/:id" component={EditarEmail} isPrivate />
         <Route exact path="/gerenciar-emails" component={GerenciarEmail} isPrivate/>

         <Route exact path="/acompanhamento" component={Acompanhamento} isPrivate/>
         <Route exact path="/editar-acompanhamento/:id" component={AlteraAcompanhamento} isPrivate />

         <Route exact path="/nova-consultoria" component={New} isPrivate/>
         <Route exact path="/nova-consultoria/:id" component={New} isPrivate/>

         <Route exact path="/lista-questionario-estilo" component={ListaEstilo} isPrivate/>
         <Route exact path="/editar-estilo/:id" component={AlterarEstilo} isPrivate />

         <Route exact path="/lista-questionario-cores" component={ListaCores} isPrivate/>
         <Route exact path="/editar-cores/:id" component={AlterarCores} isPrivate />

         <Route exact path="/lista-questionario-biotipo" component={ListaBiotipo} isPrivate/>
         <Route exact path="/editar-biotipo/:id" component={AlterarBiotipo} isPrivate />

         <Route exact path="/lista-questionario-visagismo" component={ListaVisagismo} isPrivate/>
         <Route exact path="/editar-visagismo/:id" component={AlterarVisagismo} isPrivate />

         <Route exact path="/manutencao" component={Manutencao} isPrivate/>
         <Route exact path="/relatorios" component={Relatorios} isPrivate/>

         <Route exact path="/profile" component={Profile} isPrivate/>
      </Switch>
   )
}