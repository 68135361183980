import "./style.css";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import firebase from "../../../../services/firebaseConnection";
import { toast } from "react-toastify";
import Header from "../../../../components/Header";
import Title from "../../../../components/Title";
import { format } from "date-fns";

function AlterarEstilo() {
  //Pega o id para edição
  const { id } = useParams();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [idade, setIdade] = useState("");
  const [redesocial, setRedesocial] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [personalidade, setPersonalidade] = useState("");
  const [objetivo, setObjetivo] = useState("");
  const [resposta01, setResposta01] = useState("");
  const [resposta02, setResposta02] = useState("");
  const [resposta03, setResposta03] = useState("");
  const [resposta04, setResposta04] = useState("");
  const [resposta05, setResposta05] = useState("");
  const [resposta06, setResposta06] = useState("");
  const [resposta07, setResposta07] = useState("");
  const [resposta08, setResposta08] = useState("");
  const [resposta09, setResposta09] = useState("");
  const [resposta10, setResposta10] = useState("");
  const [resposta11, setResposta11] = useState("");
  const [resposta12, setResposta12] = useState("");
  const [resposta13, setResposta13] = useState("");
  const [resposta14, setResposta14] = useState("");
  const [resposta15, setResposta15] = useState("");
  const [dataFormatada, setDataFormatada] = useState("");

  //Carrega ao abrir a tela
  useEffect(() => {
    async function loadEstilos() {
      await firebase
        .firestore()
        .collection("questionarioestilo")
        .get()
        .then((snapshot) => {
          let lista = [];

          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
            });
          });

          //Editando dados
          if (id) {
            loadId(lista);
          }
        })
        .catch((error) => {
          toast.error("Ops ocorreu um erro!");
        });
    }
    loadEstilos();
  }, [id]);

  async function loadId(lista) {
    //Buscando o Cliente pelo ID
    await firebase
      .firestore()
      .collection("questionarioestilo")
      .doc(id)
      .get()
      .then((snapshot) => {
        setNome(snapshot.data().nome);
        setEmail(snapshot.data().email);
        setTelefone(snapshot.data().telefone);
        setIdade(snapshot.data().idade);
        setRedesocial(snapshot.data().redesocial);
        setNascimento(snapshot.data().nascimento);
        setPersonalidade(snapshot.data().personalidade);
        setObjetivo(snapshot.data().objetivo);
        setResposta01(snapshot.data().resposta01);
        setResposta02(snapshot.data().resposta02);
        setResposta03(snapshot.data().resposta03);
        setResposta04(snapshot.data().resposta04);
        setResposta05(snapshot.data().resposta05);
        setResposta06(snapshot.data().resposta06);
        setResposta07(snapshot.data().resposta07);
        setResposta08(snapshot.data().resposta08);
        setResposta09(snapshot.data().resposta09);
        setResposta10(snapshot.data().resposta10);
        setResposta11(snapshot.data().resposta11);
        setResposta12(snapshot.data().resposta12);
        setResposta13(snapshot.data().resposta13);
        setResposta14(snapshot.data().resposta14);
        setResposta15(snapshot.data().resposta15);
		    setDataFormatada(format(snapshot.data().dataCadastro.toDate(), 'dd/MM/yyyy'));
      })
      .catch((error) => {
        toast.error("Ops ocorreu durante o carregamento!");
      });
  }

  function contarRespostasPorLetra(texto) {
    const contagemLetras = { A: 0, B: 0, C: 0, D: 0, E: 0, F: 0, G: 0 };
    const regex = /Resposta: ([A-G])\)/g;

    let match;
    while ((match = regex.exec(texto)) !== null) {
      const letraResposta = match[1];
      contagemLetras[letraResposta]++;
    }

    const contagemString = JSON.stringify(contagemLetras);

    return (
      <div style={{ color: "blue", fontSize: 20 }}>
        <b>RESULTADO: {contagemString}</b>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Questionário de estilo">
          <FiEye size={25} />
        </Title>

        <div id="relatorioestilo">
          <div className="box">
            <div className="small-box">
              <div className="inner col-2">
                <fieldset>
                  <div className="row">
                    <legend>Identificação</legend>
                  </div>
                  <div>
                    <span>Nome: <b>{nome}</b></span><br />
                    <span>E-mail: <b>{email}</b></span><br />
                    <span>Telefone: <b>{telefone}</b></span><br />
                    <span>Instagram: <b>{redesocial}</b></span><br />
                    <span>Idade: <b>{idade} anos</b></span><br />
                    <span>Data nascimento: <b>{nascimento}</b></span><br />
                    <span>Cadastro: <b>{dataFormatada}</b></span><br />
                    <span>Objetivo: <b>{objetivo}</b></span><br />
                    <span>Personalidade: <b>{personalidade}</b></span><br />
                    <span>Artista: <b>{resposta15}</b></span>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>

          <div className="box">
            <div className="small-box">
              <div className="inner col-2">
                <fieldset>
                  <div className="row">
                    <legend>Perguntas e Respostas</legend>
                  </div>
                    <div>
                      <span>{resposta01}</span><br />
                      <span>{resposta02}</span><br />
                      <span>{resposta03}</span><br />
                      <span>{resposta04}</span><br />
                      <span>{resposta05}</span><br />
                      <span>{resposta06}</span><br />
                      <span>{resposta07}</span><br />
                      <span>{resposta08}</span><br />
                      <span>{resposta09}</span><br />
                      <span>{resposta10}</span><br />
                      <span>{resposta11}</span><br />
                      <span>{resposta12}</span><br />
                      <span>{resposta13}</span><br />
                      <span>{resposta14}</span><br />
                      <span>{contarRespostasPorLetra(`${resposta01}${resposta02}${resposta03}${resposta04}${resposta05}${resposta06}${resposta07}${resposta08}${resposta09}${resposta10}${resposta11}${resposta12}${resposta13}${resposta14}`)}
                      </span>
                    </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlterarEstilo;
