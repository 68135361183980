import './modal.css';
import React from 'react';
import { FiMail, FiX } from 'react-icons/fi';

const MENSAGEM = [
  `Desejo-te todas cores da vida (Poesia das Cores) 
  Para hoje eu desejo-te cor Muita cor Muitas cores... Todas as cores! 
  Desejo-te uma vida alegre e colorida ou
  Da cor que te fizer melhor a alma
  Que te acalma!
  Hoje quero que pintes teu dia do jeito que quiseres
  As cores são todas tuas...
  Esmera-te!
  Quero que faças do teu dia um quadro tamanho
  E que decidas divertir-te ao máximo que puderes pintando tua melhor
  obra, Hoje!
  Uma, duas, três ou mais cores, a escolha é toda tua
  Só desejo que libertes o melhor que há em ti,
  E que despejes sobre essa tela colossal as cores que brindam teu ser.
  E quando o dia terminar que possas te sentir a melhor das artistas e
  feliz com cada cor que escolheste colorir teu dia... e a tela da tua vida!
  Mas acima de tudo, desejo que, nessa hora, possas perceber que cada
  cor que escolheste para pintar a tua obra equivaleu exatamente ao
  sorriso no rosto de cada pessoa que amas, ou simplesmente das
  passoas que por ti passaram e te viram a sorrir.
  E aí sim, desejo que escancares teu maior sorriso ao sentir o quão
  especial foste por partilhar o melhor de ti com cada uma dessas
  pessoas Hoje!
  Bem Vinda a um novo mundo colorido!`
];
export default function ModalMensagem({ close }) {
  return (
    <div className="modal">
      <div className="container">
        <button className="close" onClick={close}>
          <FiX size={23} color="#FFF" />
        </button>
        <div>        
          <h2><FiMail size={23} color="#000" /> &nbsp; Mensagem para Você &nbsp; <FiMail size={23} color="#000" /> </h2>
          <div className="row">
            <span><i>{MENSAGEM}</i></span>
          </div>
        </div>
      </div>
    </div>
  );
}
