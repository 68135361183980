import "./new.css";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiPlusCircle } from "react-icons/fi";
import { AuthContext } from "../../contexts/auth";
import firebase from "../../services/firebaseConnection";
import { toast } from "react-toastify";

export default function New() {
  //Pega o id para edição
  const { id } = useParams();
  const history = useHistory();

  const [loadingPacote, setLoadingPacote] = useState(true);
  const [pacotes, setClientes] = useState([]);
  const [pacoteSelecionado, setPacoteSelecionado] = useState(0);

  const [objetivo, setObjetivo] = useState();
  const [status, setStatus] = useState("Sim");

  const [idPacote, setIdPacote] = useState(false);

  const { user } = useContext(AuthContext);

  //Carrega ao abrir a tela
  useEffect(() => {
    async function loadCliente() {
      await firebase
        .firestore()
        .collection("pacotes")
        .get()
        .then((snapshot) => {
          let lista = [];

          snapshot.forEach((doc) => {
            lista.push({
              id: doc.id,
              nomePacote: doc.data().nomePacote,
            });
          });
          if (lista.length === 0) {
            toast.warning("Nenhuma consultoria encontrada");
            setClientes([{ id: 1, nomePacote: "Freela" }]);
            setLoadingPacote(false);
            return;
          }

          setClientes(lista);
          setLoadingPacote(false);

          //Editando dados
          if (id) {
            loadId(lista);
          }
        })
        .catch((error) => {
          console.log(error);
          toast.error("Ops ocorreu um erro!");
          setLoadingPacote(false);
          setClientes([{ id: 1, nomePacote: "" }]);
        });
    }

    loadCliente();
  }, [id]);

  async function loadId(lista) {
    //Buscando o Cliente pelo ID
    await firebase
      .firestore()
      .collection("consultoria")
      .doc(id)
      .get()
      .then((snapshot) => {
        setObjetivo(snapshot.data().objetivo);
        setStatus(snapshot.data().status);

        //Achar index do cliente no select
        let index = lista.findIndex(
          (item) => item.id === snapshot.data().clienteId
        );
        setPacoteSelecionado(index);

        //Tentando Editar
        setIdPacote(true);
      })
      .catch((error) => {
        toast.error("Ops ocorreu durante o carregamento!");
        setIdPacote(false);
        console.log(error);
      });
  }

  function mudaPacote(e) {
    setPacoteSelecionado(e.target.value);
  }

  async function registrarConsultoria(e) {
    e.preventDefault();

    //Editando um chamado
    if (idPacote) {
      await firebase
        .firestore()
        .collection("consultoria")
        .doc(id)
        .update({
          pacote: pacotes[pacoteSelecionado].nomePacote,
          clienteId: pacotes[pacoteSelecionado].id,
          objetivo: objetivo,
          status: status,
          userId: user.uid,
        })
        .then(() => {
          toast.success("Atualizado com Sucesso!");
          setPacoteSelecionado(0);
          setObjetivo("");
          history.push("/dashboard");
        })
        .catch((error) => {
          toast.error("Ops ocorreu um erro, tente novamente!");
          console.log(error);
        });

      return;
    }

    //Criando uma nova consultoria
    if (objetivo === undefined) {
      toast.error("O campo objetivo deve ser preenchido!");
      return;
    }
    await firebase
      .firestore()
      .collection("consultoria")
      .add({
        dataCadastro: new Date(),
        pacote: pacotes[pacoteSelecionado].nomePacote,
        clienteId: pacotes[pacoteSelecionado].id,
        objetivo: objetivo,
        status: status,
        userId: user.uid,
      })
      .then(() => {
        toast.success("Cadastrado com Sucesso!");
        setObjetivo("");
        setPacoteSelecionado(0);
      })
      .catch((error) => {
        toast.error("Ops ocorreu um erro, tente novamente!");
        console.log(error);
      });
  }

  function mudaStatus(e) {
    setStatus(e.target.value);
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Nova consultoria">
          <FiPlusCircle size={25} />
        </Title>

        <div className="container">
          <form
            className="form-profile customers"
            onSubmit={registrarConsultoria}
          >
            <label>Pacote</label>
            {loadingPacote ? (
              <input
                type="text"
                disabled={true}
                value="Carregando pacotes..."
              />
            ) : (
              <select value={pacoteSelecionado} onChange={mudaPacote}>
                {pacotes.map((item, index) => {
                  return (
                    <option key={item.id} value={index}>
                      {item.nomePacote}
                    </option>
                  );
                })}
              </select>
            )}
            <label>Objetivo</label>
            <textarea
              type="text"
              placeholder="Descreva seu problema (opcional)"
              value={objetivo}
              onChange={(e) => setObjetivo(e.target.value)}
            />
            <label>Publicar?</label>
            <div className="status">
              <input
                type="radio"
                name="radio"
                value="Sim"
                checked={status === "Sim"}
                onChange={mudaStatus}
              />
              <span>Sim</span>

              <input
                type="radio"
                name="radio"
                value="Não"
                checked={status === "Não"}
                onChange={mudaStatus}
              />
              <span>Não</span>
            </div>
            <button type="submit">Registrar</button>
          </form>
        </div>
      </div>
    </div>
  );
}
