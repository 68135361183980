import React, { SelectHTMLAttributes } from 'react';
import './styles.css'

interface SelectProps extends SelectHTMLAttributes <HTMLSelectElement> {
    name: string,
    label: string
    options: Array<{
        value: string,
        label: string
    }>
}

const SelectButton: React.FC<SelectProps> = ({label, name, options, ...rest }) => {
    return (
        <div className="select-blocks">
            <label htmlFor={name}>{label}</label>
            <select value="" id={name} {...rest}>
                <option value="" disabled hidden>Selecione...</option>
                {options.map((options) => {
                    return <option key={options.value} value={options.value}>{options.label}</option>
                })}
            </select>
        </div>
    )
}

export default SelectButton