import './listaemail.css'
import React, { useState, useEffect } from 'react';
import Header from '../../../components/Header'
import Title from '../../../components/Title'
import { FiEdit2, FiXCircle, FiCheckSquare, FiAtSign } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import firebase from '../../../services/firebaseConnection'
import { toast } from 'react-toastify';
import Loader from "react-loader-spinner";
const listRef = firebase.firestore().collection('listaemails').orderBy('email', 'asc');

export default function ListaEmail() {

  const [email, setEmail] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingMore, setLoadingMore] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [lastDocs, setLastDocs] = useState()

  useEffect(() => {
    async function loadCadastro() {
      await listRef.limit(10).get()
        .then((snapshot) => {
          updateState(snapshot)
        })
        .catch(() => {
          toast.error('Ops ocorreu algum erro')
          setLoadingMore(false)
        })

      setLoading(false)
    }
    loadCadastro();

    return () => { }
  }, [])

  async function updateState(snapshot) {
    const isCollectionEmpyty = snapshot.size === 0;
    if (!isCollectionEmpyty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          email: doc.data().email,
          check: doc.data().check,
          bonus: doc.data().bonus,
        })
      })

      const lastDoc = snapshot.docs[snapshot.docs.length - 1];  //ultimo documento da lista
      setEmail(email => [...email, ...lista])
      setLastDocs(lastDoc)
    } else {
      setIsEmpty(true)
    }
    setLoadingMore(false)
  }

  async function buscarMais() {
    setLoadingMore(true)
    await listRef.startAfter(lastDocs).limit(20).get()
      .then((snapshot) => {
        updateState(snapshot)
      })
  }

  function checkEbook(id) {
    const resultado = window.confirm('Confirmar envio de e-book?');
    if (resultado) {
      firebase.firestore().collection('listaemails')
        .doc(id)
        .update({
          check: true,
        })
        .then(() => {
          toast.success('Confirmado com Sucesso!');
        })
        .catch(() => {
          toast.error('Ocorreu um erro, tente novamnete!');
        });
    } else {
      return;
    }
  }
  function checkBonus(id) {
    const resultado = window.confirm('Confirmar envio de bônus?');
    if (resultado) {
      firebase.firestore().collection('listaemails')
        .doc(id)
        .update({
          bonus: true,
        })
        .then(() => {
          toast.success('Confirmado com Sucesso!');
        })
        .catch(() => {
          toast.error('Ocorreu um erro, tente novamnete!');
        });
    } else {
      return;
    }
  }
  if (loading) {
    return (
      <div>
        <Header />
        <div className="content">
          <Title name="Novos cadastro">
            <FiAtSign size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando e-mails...</span>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Novos e-mail">
          <FiAtSign size={25} />
        </Title>
        {email.length === 0 ? (
          <div className="container dashboard">
            <span>Nenhum e-mail registrado</span>
          </div>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col">E-mail</th>
                  <th scope="col">E-Book enviado?</th>
                  <th scope="col">Bônus enviado?</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {email.map((item, index) => {
                  return (
                    <tr key={item.id}>
                      <td data-label="E-mail">{item.email}</td>
                      <td data-label="E-book enviado?">
                        {item.check === false
                          ? <FiXCircle title='Confirmar envio de e-book?' color='#f34336' style={{cursor: 'pointer'}} onClick={() => checkEbook(item.id)} size={25} />                           
                          : <FiCheckSquare title='E-book enviado' color='rgb(92 182 92)' size={25} />}
                      </td>
                      <td data-label="Bônus Enviado?">
                        {item.bonus === false
                          ? <FiXCircle color='#f34336' title='Confirmar envio de bônus?' style={{cursor: 'pointer'}} onClick={() => checkBonus(item.id)} size={25} />                           
                          : <FiCheckSquare title='Bônus enviado' color='rgb(92 182 92)' size={25} />}
                      </td>
                      <td data-label="Acão">
                        <Link to={`/editar-email/${item.id}`} className="action" style={{ backgroundColor: '#F6a935' }}>
                          <FiEdit2 color="#FFF" size={17} />
                        </Link>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {loadingMore && <h3 style={{ textAlign: 'center', marginTop: 15 }}><Loader
              type="Puff"
              color="#e83e8c"
              height={80}
              width={80}
              timeout={3000} //3 secs
            /></h3>}
            {!loadingMore && !isEmpty &&
              <button className="btn-more" onClick={buscarMais} >Buscar mais</button>}
          </>
        )}
      </div>
    </div>
  );
}
