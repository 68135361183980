export const RESPOSTA_PERGUNTA01 = [
  "A) Em roupas práticas e confortáveis, como jeans e camisetas",
  "B) Em peças elegantes e clássicas, como blazers e calças de alfaiataria",
  "C) Em roupas leves e fluidas, com estampas delicadas e cores suaves",
  "D) Em peças que destacam a sensualidade, com transparências e roupas justas",
  "E) Em looks modernos e ousados, com mistura de cores, estampas e texturas",
  "F) Em roupas que chamam a atenção, como cores fortes, volumes exagerados e detalhes marcantes",
  "G) Em um estilo que varia conforme meu humor e ocasião"
];

export const RESPOSTA_PERGUNTA02 = [
  "A) Tênis ou calçados confortáveis",
  "B) Acessórios clássicos, como relógios ou pulseiras",
  "C) Prefere detalhes delicados ou itens mais discretos",
  "D) Sapatos que destacam, como sapatos ou botas chamativas",
  "E) Óculos de sol ou colares impactantes",
  "F) Acessórios geométricos ou pulseiras volumosas",
  "G) Uma variedade de acessórios para combinar com meu estilo do dia"
];

export const RESPOSTA_PERGUNTA03 = [
  "A) Neutras, como preto, branco e cinza",
  "B) Tons pastéis e cores suaves",
  "C) Cores vibrantes e estampas alegres, vivas, florais",
  "D) Preto, vermelho e outras cores mais intensas",
  "E) Uma mistura de cores e estampas, sem restrições",
  "F) Cores fortes e contrastantes, como vermelho e azul elétrico",
  "G) Todas as cores, dependendo do meu humor e do dia"
];

export const RESPOSTA_PERGUNTA04 = [
  "A) Natural e prático",
  "B) Elegante e impecável",
  "C) Suave com um ar romântico",
  "D) Marcante e sedutor",
  "E) Moderno e ousado",
  "F) Dramático e impactante",
  "G) Versátil, variando conforme a ocasião"
];

export const RESPOSTA_PERGUNTA05 = [
  "A) Gosto de me vestir de forma casual e confortável",
  "B) Prefiro peças clássicas e atemporais",
  "C) Sou romântico(a) e adoro looks delicados",
  "D) Gosto de me sentir sensual e poderoso(a)",
  "E) Sou criativo(a) e adoro experimentar novas tendências",
  "F) Gosto de looks marcantes e dramáticos",
  "G) Gosto de variar meu estilo conforme meu humor e ocasião"
];

export const RESPOSTA_PERGUNTA06 = [
  "A) Compro apenas quando preciso de algo",
  "B) Faço compras planejadas",
  "C) Compro pensando no custo-benefício, não só na moda, mas nas coordenações",
  "D) Adoro ir às compras",
  "E) Adoro comprar peças que valorizam meu corpo",
  "F) Adoro lugares alternativos e brechós",
  "G) Só compro quando gosto muito de algo"
];

export const RESPOSTA_PERGUNTA07 = [
  "A) São fáceis de cuidar",
  "B) São encorpados",
  "C) São refinados",
  "D) São fluidos e leves",
  "E) Moldam o corpo",
  "F) Têm textura interessante",
  "G) São bem estruturados"
];

export const RESPOSTA_PERGUNTA08 = [
  "A) Listras coloridas e xadrezes",
  "B) Listras de duas cores",
  "C) Jacquard",
  "D) Florais",
  "E) Estampas de animais",
  "F) Estampas étnicas",
  "G) Estampas geométricas"
];

export const RESPOSTA_PERGUNTA09 = [
  "A) Soltas e confortáveis",
  "B) Retas e não reveladoras",
  "C) Com caimento perfeito e suavemente estruturado",
  "D) Fluidas e com linhas suaves",
  "E) Justas, moldando o corpo",
  "F) Desestruturadas, com sobreposição de peças",
  "G) Estruturadas e controladas"
];

export const RESPOSTA_PERGUNTA10 = [
  "A) Jeans com blusas confortáveis",
  "B) Camisa com calça de alfaiataria",
  "C) Peças clean que pedem um super acessório",
  "D) Peças trabalhadas, com bordados e detalhes",
  "E) Peças exuberantes",
  "F) Peças vintage",
  "G) Peças da última tendência"
];

export const RESPOSTA_PERGUNTA11 = [
  "A) Não gosto muito de acessórios, uso o básico",
  "B) Clássicos e discretos",
  "C) Adoro acessórios que completam a roupa",
  "D) Adoro me enfeitar, gosto de quase todos",
  "E) Que tenham muito balanço",
  "F) Diferentes, étnicos, antigos",
  "G) Exagerados e ousados que chamam atenção"
];

export const RESPOSTA_PERGUNTA12 = [
  "A) Gosto de sapatos confortáveis",
  "B) Os que combinam com o look todo",
  "C) Gosto dos que estão na moda",
  "D) Prefiro os com mais detalhes",
  "E) Prefiro sapatos sociais",
  "F) Coloridos ou vintage",
  "G) Gosto de sapatos diferentes e criativos"
];

export const RESPOSTA_PERGUNTA13 = [
  "A) Qualquer um que não requeira muitos cuidados",
  "B) Corte durável e aparência elegante",
  "C) Moderno e atualizado",
  "D) Prefiro um cabelo um pouco mais volumoso ou longo",
  "E) Repicado e estiloso",
  "F) Mudo conforme meu humor",
  "G) Sofisticado, moderno ou exagerado"
];

export const RESPOSTA_PERGUNTA14 = [
  "A) Ir a um churrasco na casa dos amigos",
  "B) Ir a um concerto ou sinfônica",
  "C) Ir a um vernissage ou avant-première",
  "D) Ir a um jantar à luz de velas a dois",
  "E) Ir a uma discoteca para dançar",
  "F) Assistir a um filme cult",
  "G) Ir a um evento bem badalado"
];
