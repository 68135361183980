import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from 'react-bootstrap';
import ImgSobre from '../../assets/images/perfilsobre.jpeg';
import './styles.css'

function Sobre() {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            height: '50vh',
            fontFamily: 'Arial, Helvetica, sans-serif',
        },
        icon: {
            color: 'var(--color-padrao)',
            fontSize: '2.5rem',
            cursor: 'pointer'
        },
        sobre: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
            },

        },
        title2: {
            flexGrow: '1',
            color: '#FFF9EC',
            textShadow: '#000 -1px -1px',
        },
        container: {
            textAlign: 'center',
        },
        titleHome: {
            color: 'var(--color-padrao)',
            fontSize: '7rem',
            textShadow: '#000 -1px -1px',
            '@media(max-Width: 600px)': {
                fontSize: '4rem'
            }
        }
    }));

    const classes = useStyles();
    const [checked] = useState(false);

    return (
        <>
            <div className={classes.root} id="header-sobre">
                <Collapse
                    in={checked}
                    {...(checked ? { timeout: 1000 } : {})}
                    collapsedHeight={50}
                >
                    <div className={classes.container}>
                        <h1 className={classes.titleHome}>
                            Um pouco mais <span className={classes.title2}> sobre mim</span>
                            <hr className='linha' />
                        </h1>
                    </div>
                </Collapse>
            </div>
            <div>

                <div className={classes.sobre} id="sobre-mim">
                    <div className='sobre padrao'>
                        <div className='textosobre'>
                            <img className='imgmay' src={ImgSobre} alt="Foto sobre Mayy" />
                            <p className='texto1'>
                                Sou <b>APAIXONADA</b> por moda desde minha infância, sou graduada em <b>Designer de Moda</b>, com <b>MBA em Moda Criativa</b>, e mais alguns cursos de aperfeiçoamento como <b>Consultoria de imagem, Consultoria de Estilo,  Especialista em Customizações, Styling de Moda, Analista de Coloração Pessoal, Visual Merchandising e Consultoria em Visagismo para imagem pessoal.</b>
                            </p>
                            <p className='texto2'>Tenho como objetivo abrilhantar ainda mais sua identidade visual, trabalhar com carinho e presteza no seu <b>Designer de Imagem </b>desejado. Além de propor-te uma nova fase, onde trabalharemos a beleza e a moda de dentro para fora.
                                Sou casada, apaixonada pela minha família e pela minha área de formação.
                            </p>
                            <p className='texto2'>
                                Venha comigo, para juntas descobrirmos  esse novo universo que te espera de braços abertos.
                            </p>
                            <p className='textorodape' id='sub'>
                                Mayy - Personal Stylist
                            </p>
                            <hr className='linha' />

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sobre