export const RESPOSTA_PERGUNTA03 = [
  "A) Sim",
  "B) Não",
];

export const RESPOSTA_PERGUNTA06 = [
  "A) Bornzeada",
  "B) Queimada",
];

export const RESPOSTA_PERGUNTA08 = [
  "A) Azul Royal",
  "B) Laranja",
];

export const RESPOSTA_PERGUNTA12 = [
  "A) Combinar cores",
  "B) Prefero os tons neutros",
];

export const RESPOSTA_PERGUNTA18 = [
  "A) Dourados",
  "B) Prateados",
];