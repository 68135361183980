import React, { useState } from 'react';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiAtSign, FiMail } from 'react-icons/fi';
import firebase from '../../services/firebaseConnection';
import { toast } from 'react-toastify';

export default function GerenciarEmail() {
  const [email, setEmail] = useState('');

  async function cadastrar(e) {
    e.preventDefault();
    if (email !== '') {
      await firebase
        .firestore()
        .collection('listaemails')
        .add({
          email: email,
          check: false,
          bonus: false,
        })
        .then(() => {
          setEmail('');
          toast.success('E-mail cadastrado com Sucesso!');
        }).catch((error) => {
          toast.error('Erro ao cadastrar essa e-mail!');
        })
    } else {
      toast.error('Preencha o e-mail.');
    }
  }
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Cadastro de e-mails">
          <FiAtSign size={25} />
        </Title>
        <div className="container">
          <form className="form-profile customers" onSubmit={cadastrar}>
            <label>Novo e-mail</label>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Digite novo o e-mail"
            />
            <button type="submit">Cadastrar</button>
          </form>
        </div>
      </div>

    </div>
  );
}
