import React, { useState, useContext } from "react";
import "./profile.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { FiSettings, FiUpload } from "react-icons/fi";
import { AuthContext } from "../../contexts/auth";
import { toast } from "react-toastify";
import avatar from "../../assets/avatar.png";
import firebase from "../../services/firebaseConnection";

export default function Profile() {
  const { user, logout, setUser, storageUser } = useContext(AuthContext);

  const [name, setName] = useState(user && user.nome);
  const [email] = useState(user && user.email);
  const [avatarUrl, setAvatarUrl] = useState(user && user.avatarUrl);
  const [imageAvatar, setImageAvatar] = useState(null);

  function carregaArquivo(e) {
    //Recebe imagem
    if (e.target.files[0]) {
      const image = e.target.files[0];

      if (image.type === "image/jpeg" || image.type === "image/png") {
        setImageAvatar(image);
        setAvatarUrl(URL.createObjectURL(e.target.files[0]));
      } else {
        alert("Envie uma imagem do tipo PNG ou JPEG");
        setImageAvatar(null);
        return null;
      }
    }
  }

  async function fazerUpload() {
    const usuarioAtual = user.uid;

    const uploadTask = await firebase
      .storage()
      .ref(`images/${usuarioAtual}/${imageAvatar.name}`)
      .put(imageAvatar)
      .then(async () => {
        toast.success("Atualizado com Sucesso!");

        await firebase
          .storage()
          .ref(`images/${usuarioAtual}`)
          .child(imageAvatar.name)
          .getDownloadURL()
          .then(async (url) => {
            let urlFoto = url;

            await firebase
              .firestore()
              .collection("users")
              .doc(user.uid)
              .update({
                avatarUrl: urlFoto,
                nome: name,
              })
              .then(() => {
                let data = {
                  ...user,
                  avatarUrl: urlFoto,
                  nome: name,
                };
                setUser(data);
                storageUser(data);
              });
          });
      });
  }

  async function salvarDados(e) {
    e.preventDefault();

    if (imageAvatar === null && name !== "") {
      await firebase
        .firestore()
        .collection("users")
        .doc(user.uid)
        .update({
          nome: name,
        })
        .then(() => {
          let data = {
            ...user,
            nome: name,
          };
          setUser(data);
          storageUser(data);
          toast.success("Atualizado com Sucesso!");
        })
        .catch(() => {
          toast.error("Ops, ocorreu um erro!");
        });
    } else if (name !== "" && imageAvatar !== null) {
      fazerUpload();
    }
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Meu perfil">
          <FiSettings size={25} />
        </Title>

        <div className="container-profile">
          <form className="form-profile" onSubmit={salvarDados}>
            <label className="label-avatar">
              <span>
                <FiUpload color="#FFF" size={25} />
              </span>
              <input type="file" accept="image/*" onChange={carregaArquivo} />
              <br />
              {avatarUrl === null ? (
                <img
                  src={avatar}
                  width="250"
                  height="250"
                  alt="Foto perfil usuário"
                />
              ) : (
                <img
                  src={avatarUrl}
                  width="250"
                  height="250"
                  alt="Foto perfil usuário"
                />
              )}
            </label>

            <label>Nome</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <label>Email</label>
            <input type="text" value={email} disabled={true} />
            <button type="submit">Salvar</button>
          </form>
        </div>
        <div className="container">
          <button className="logout-btn" onClick={() => logout()}>
            Sair
          </button>
        </div>
      </div>
    </div>
  );
}
