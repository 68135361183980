import './loja.css';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Collapse } from '@material-ui/core';
import { Link as Scroll } from 'react-scroll';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Instagram, LinkedIn, Facebook, Email, Call } from '@material-ui/icons';

//IMAGEM PARA OS CARDS
import Logo from "../Landing/img/logo-nova.png"
import Tecido from "./img/tecido.jpeg"
import Paleta from "./img/paleta.jpeg"
import Placa from "./img/placas.jpeg"
import Cartela from "./img/cartela.jpeg"



import Detalhes from './Detalhes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '55vh',
    fontFamily: 'Arial, Helvetica, sans-serif'
  },
  icon: {
    color: 'var(--color-padrao)',
    fontSize: '3rem',
    cursor: 'pointer'
  },
  title2: {
    flexGrow: '1',
    color: '#FFF9EC',
    textShadow: '#000 -1px -1px'
  },
  colorText: {
    color: 'var(--color-padrao)',
    textShadow: '#000 -1px -1px'
  },
  titleHome: {
    color: 'var(--color-padrao)',
    fontSize: '7rem',
    textAlign: 'center',
    textShadow: '#000 -1px -1px',
    '@media(max-Width: 600px)': {
      fontSize: '4rem'
    }
  },
  goDown: {
    color: 'var(--color-padrao)',
    fontSize: '3.5rem',
    backgroundColor: '#FFF',
    borderRadius: '50px'
  },
}));


export default function Loja() {
  const classes = useStyles();
  const [detail, setDetail] = useState()
  const [showPostModal, setShowPostModal] = useState(false)
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);

  const produtos = [
    { id: 1, descricao: "KIT Completo de 47 Cards ao Total - Análise de Coloração pessoal. 1-Painel para montar o Colorboard. 2-Cards Teperatura Fria. 2-Cards Teperatura Quente. 4-Cards para definição Sazonal. 3-Cards Contraste Colorido. 3-Cards Contraste Preto e Branco. 3-Cards Outono Puro, Suave e Profundo. 3-Cards Inverno Frio, Brilhante e Profundo. 3-Cards Verão Claro, Puro e Suave. 3-Cards Primavera Pura, Clara e Brilhante. 12-Cartelas de Cores Estação Mãe e Círculo Cromático. 6-Combinações Cromáticas + Ficha de Anamnese Colors. Esse é um material de apoio para a realização da análise de coloração pessoal pelo método sazonal expandido. Kit vai completo com as 12 estações + temperaturas quentes e frias + contrastes preto e branco e contraste colorido. # DE BÔNUS ainda vai as 12 Cartela-mãe de cada estação + o Círculo Cromático + 6 Combinações Cromáticas. Os tecidos digitais foram criados pela Consultora de Estilo, Personal Colors e Designer de Moda: Mayy Machado - Tamanho: Paisagem-A4, com formato de rosto vazado. Tamanho 29,7x21 - ENTREGA EM FORMATO DIGITAL - PDF", imagem: Tecido, link: "https://sun.eduzz.com/1653400" },
    { id: 2, descricao: "KIT com 12 Paletas de Estampas – contendo 96 estampas de Alto, Médio e Baixo Contraste. • 12 Paletas de Estampas • 32 estampas Alto Contraste • 32 estampas Médio Contraste •	32 estampas Baixo Contraste **BÔNUS: => PALETA DE CORES EXPRESS => CÍRCULO CROMÁTICO. Esse é um material de apoio para ser entregue as clientes após a realização da Análise de Coloração Pessoal. Pode ser impresso ou usado de forma digital. O Kit de Palestas de Estampas vai completo com as 12 cartelas, 32 estampas de contraste alto, médio e baixo. As paletas digitais foram criadas pela Consultora de Estilo, Personal Colors e Designer de Moda: Mayy Machado. Tamanho: Paisagem-A2, com formato de encaixe do rosto. Tamanho 8,89cm x 5,08cm. • ENTREGA EM FORMATO DIGITAL • ALTA RESOLUÇÃO • PRONTO PARA IMPRESSÃO.", imagem: Paleta, link: "https://sun.eduzz.com/1656687" },
    { id: 3, descricao: "KIT com 26 Palcas para Análise de Coloração pessoal. •	2 Placas Teperatura Fria. •	2 Placas Teperatura Quente. •	3 Placas Contraste Colorido. • 3 Placas Contraste Preto e Branco. • 4 Placas Outono Estação-mae, Puro, Suave e Profundo. • 4 Placas Inverno Estação-mãe, Frio, Brilhante e Profundo. • 4 Placas Verão Estação-mãe, Claro, Puro e Suave. • 4 Placas Primavera Estação-mãe, Pura, Clara, Brilhante. • Círculo Cromático. • 6 Harmonias Cromáticas. • 1 Ficha de Anamnese Colors. Esse é um material de apoio para a realização da Análise de Coloração Pessoal pelo método sazonal expandido. Pode ser impresso ou usado de forma digital. O Kit vai completo com as 12 estações + temperaturas + contrastes preto e branco e contraste colorido.     De bônus ainda vai um lindo Círculo Cromático (digital) para entregar para suas clientes. Os tecidos digitais foram criados pela Consultora de Estilo, Personal Colors e Designer de Moda: Mayy Machado. Tamanho: Paisagem-A2, com formato de encaixe do rosto. Tamanho 59,7cm x 42cm. • ENTREGA EM FORMATO DIGITAL • ALTA RESOLUÇÃO • PRONTO PARA IMPRESSÃO.", imagem: Placa, link: "https://sun.eduzz.com/1655553" },
    { id: 4, descricao: "KIT com 12 cartelas de coloração pessoal – Digital + BÔNUS •	Kit com 12 cartelas método sazonal expandido. Após a compra terá o prazo de envio dos arquivo digital (PDF) em alta resolução para impressão em até 7 dias úteis! Um Material perfeito para presentear suas clientes após a analise de coloração pessoal, tanto presencial quanto digital! O tamanho é ótimo também para levar na bolsa e ajudar nas compras mais inteligentes e assertivas!! A cartela contem: + de 150 Cores da Cada Estação-mãe Cores de makes Cores de Batons Cores de Blush Cores de Sombras Cores de Delineador Cores de Tons de Cabelos. ENTREGA DO PRODUTO: Em Forma Digital em PDF. Kit com Cartela de Cores completa das 12 Estações – contendo + de 150 cores em cada Cartela. • Verão Suave • Verão Puro •	Verão Claro • Primavera Pura •	Primavera Clara • Primavera Brilhante • Outono Suave •	Outono Puro • Outono Profundo • Inverno Frio • Inverno Brilhante • Inverno Profundo **BÔNUS: => CERTIFICADO DE PARABÉNS PARA CLIENTE DAS 12 ESTAÇÕES => CÍRCULO CROMÁTICO => PALETA DE HARMONIA CROMÁTICA DE CADA ESTAÇÃO-MÃE. Esse é um material de apoio para ser entregue as suas clientes após a realização da Análise de Coloração Pessoal. Pode ser impresso ou usado de forma digital. O Kit de Cartela de Cores vai completo com as cartelas das 12 estações, são mais de 150 cores em cada cartela. As cartelas de cores são digitais, e foram criadas pela Consultora de Estilo, Personal Colors e Designer de Moda: Mayy Machado. Tamanho: Formato A4, com 6 fileiras de cores deitadas, perfeitas para impressão de leque caso queira. Tamanho 21cm x 29,07cm. •	ENTREGA EM FORMATO DIGITAL • ALTA RESOLUÇÃO • PRONTO PARA IMPRESSÃO.", imagem: Cartela, link: "https://sun.eduzz.com/1657438" }
  ]

  function carregaDados(item) {

    setShowPostModal(!showPostModal)

    setDetail(item)
  }
  return (
    <>
      <header className="layout-cabecalho  layout-cabecalho--home">
        <div className="container">
          <nav className="barra">
            <a href="/"><img src={Logo} width="100" height="120" alt="Mayy Stylist" /></a>
            <div className="barra__menu">
              <button className="botao-chaveador  js-botao-chaveador"></button>
              <ul className="menu-barra  js-menu">
                <li className="menu__item">
                  <a href="/">Voltar</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>

      <div className={classes.root}>
        <div className={classes.container}>
          <h1 className={classes.titleHome}>
            Sua Loja Virtual para Consultoras
          </h1>
          <Scroll to="home" smooth={true} className="pulse">
            <IconButton>
              <ExpandMoreIcon className={classes.goDown} />
            </IconButton>
          </Scroll>
        </div>
      </div>

      <div className="row-cards">
        {
          produtos.map((dado, i) => (
            <Collapse key={i + 1}
              in={checked}
              {...(checked ? { timeout: 1000 } : {})}
              collapsedHeight={60}
            >
              <div className="card-card padrao-borda" onClick={() => carregaDados(dado)}>
                <img className="image-card" src={dado.imagem} alt="Consultoria" />
              </div>
            </Collapse>
          ))
        }
      </div>

      {showPostModal && (
        <Detalhes
          conteudo={detail}
          close={carregaDados}
        />
      )}
      <a className="whatsapp-link pulse" href="https://api.whatsapp.com/send?phone=5561998894670&text=Olá estilosa, seja muito bem vinda...🤩✨ Me conte, no que posso te ajudar?.." target="_blank" rel="noreferrer">
        <i className="fa fa-whatsapp"></i>
      </a>
      <div className="footer" id='contato'>
        <div className="footer-menu">

          <h2>Redes sociais / Contatos</h2>
          <div>
            <IconButton>
              <a href='https://www.instagram.com/mayy.stylist/' title='Instagram' target="_blank" rel="noreferrer"><Instagram className={classes.icon} /></a>
            </IconButton>
            <IconButton>
              <a href='https://m.facebook.com/mayy.stylist' title='Facebook' target="_blank" rel="noreferrer"><Facebook className={classes.icon} /></a>
            </IconButton>
            <IconButton>
              <a href='https://www.linkedin.com/in/mayystylist/' title='Linkedin' target="_blank" rel="noreferrer"><LinkedIn className={classes.icon} /></a>
            </IconButton>
            <IconButton>
              <a href='mailto:mayystylist@gmail.com' title='E-mail' target="_blank" rel="noreferrer"><Email className={classes.icon} /></a>
            </IconButton>
            <IconButton>
              <a href="https://api.whatsapp.com/send?phone=5561998894670&text=Olá estilosa, seja muito bem vinda...🤩✨ Me conte, no que posso te ajudar?..s" title='Whatsapp' target="_blank" rel="noreferrer"><Call className={classes.icon} /></a>
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
}
