import React, {useEffect } from 'react';
import "./styles.css";
import "./bootstrap-grid.min.css";
import Logo from "./imagens/logo-nova.png"

function Home() {
    useEffect(() => {

        var alternador = document.querySelector('.js-botao-chaveador');

        alternador.onclick = function () {
            var menu = document.querySelector('.js-menu');
            menu.classList.toggle('menu--exibindo');
        }
    }, []);

    return (
        <div>
            <header className="layout-cabecalho  layout-cabecalho--home">
                <div className="container">

                    <nav className="barra">
                        <a href="/"><img src={Logo} width="100" height="120" alt="Mayy Stylist" /></a>

                        <div className="barra__menu">
                            <button className="botao-chaveador  js-botao-chaveador"></button>

                            <ul className="menu-barra  js-menu">
                                <li className="menu__item">
                                    <a href="#sobre">Sobre</a>
                                </li>
                                <li className="menu__item">
                                    <a href="/ebooks" target="_blank">E-books</a>
                                </li>
                                <li className="menu__item">
                                    <a href="#agendamento">Agendar</a>
                                </li>
                                <li className="menu__item">
                                    <a href="#depoimentos">Depoimentos</a>
                                </li>
                                <li className="menu__item">
                                    <a href="#contato">Contato</a>
                                </li>
                                <li className="menu__item">
                                    <a href="/loja-virtual" target="_blank">Loja</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    );
}

export default Home;