import React, {useContext, useState} from 'react';
import logo from '../../assets/logo.png';
import {Link} from 'react-router-dom';
import {AuthContext} from '../../contexts/auth';
import { toast } from 'react-toastify'

function Register () {
  
  const [name, setName] = useState ('');
  const [email, setEmail] = useState ('');
  const [password, setPassword] = useState ('');

  const {cadastrarUsuario, loadingAuth} = useContext (AuthContext);

  function handleSubmit (e) {
    e.preventDefault ();

    if(name !== '' && email !== '' && password !== ''){
      cadastrarUsuario(email, password, name);
    } else {
      toast.error("Preenchado todos os campos!")
      return null
    }
  }

  return (
    <div className="container-center">
      <div className="login">
        <div className="login-area">
          <img src={logo} alt="Logo do Sistema" />
        </div>
        <form onSubmit={handleSubmit}>
          <h1>Criar uma conta</h1>
          <input
            type="text"
            placeholder="Seu nome"
            value={name}
            onChange={e => setName (e.target.value)}
          />
          <input
            type="email"
            placeholder="email@email.com"
            value={email}
            onChange={e => setEmail (e.target.value)}
          />
          <input
            type="password"
            placeholder="******"
            value={password}
            onChange={e => setPassword (e.target.value)}
          />
          <button type="submit">
          {loadingAuth ? 'Carregando...' : 'Cadastrar'}
          </button>
        </form>
        <Link to="/">Já tem uma conta? Entre!</Link>
      </div>
    </div>
  );
}

export default Register;
