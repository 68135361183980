import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

let firebaseConfig = {
   apiKey: "AIzaSyDRZcINOLuPWSqgU-ImrjYpVrnLsbcgGMo",
    authDomain: "mayystylist-2e82b.firebaseapp.com",
    databaseURL: "https://mayystylist-2e82b-default-rtdb.firebaseio.com",
    projectId: "mayystylist-2e82b",
    storageBucket: "mayystylist-2e82b.appspot.com",
    messagingSenderId: "923336470005",
    appId: "1:923336470005:web:83acfc3ce3fd3e6241c4bc",
    measurementId: "G-JWX53J7Z6E"
 };
 // Initialize Firebase
if(!firebase.apps.length){
   firebase.initializeApp(firebaseConfig);
}

export default firebase;
