import './header.css';
import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import avatar from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { FiSettings, FiLayers, FiCoffee, FiCheckCircle, FiMail, FiAtSign, FiUsers, FiSearch, FiSlack, FiAward } from 'react-icons/fi';
import { GoChecklist } from 'react-icons/go';

export default function Header() {
  const { user } = useContext(AuthContext);

  return (
    <div className="sidebar">
      <div>
        <img src={user.avatarUrl === null ? avatar : user.avatarUrl} alt="Foto avatar" />
      </div>

      <Link to="/pre-cadastro">
        <FiCheckCircle color="#FFF" size={24} />Pré cadastro
      </Link>
      <Link to="/acompanhamento">
        <FiCoffee color="#FFF" size={24} />Acompanhamento
      </Link>
      <Link to="/manutencao">
        <FiLayers color="#FFF" size={24} />Manutenção
      </Link>
      <Link to="/lista-questionario-estilo">
        <FiAward  color="green" size={24} />Quiz de Estilo
      </Link>
      <Link to="/lista-questionario-cores">
        <FiSlack color="red" size={24} />Quiz de Cores
      </Link>
      <Link to="/lista-questionario-biotipo">
        <FiUsers color="blue" size={24} />Quiz de Biotípos
      </Link>
      <Link to="/lista-questionario-visagismo">
        <FiSearch color="yellow" size={24} />Quiz de Visagismo
      </Link>
      <Link to="/gerenciar-emails">
        <FiAtSign color="#FFF" size={24} />Gerenciar e-mails
      </Link>
      <Link to="/lista-emails">
        <FiMail color="#FFF" size={24} />Lista de e-mails
      </Link>
      <Link to="/relatorios">
        <GoChecklist color="#FFF" size={24} />Relatórios
      </Link>
      <Link to="/profile">
        <FiSettings color="#FFF" size={24} />Configurações
      </Link>
    </div>
  );
}
