import "./styles.css";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import warningIcon from "../../../../assets/icons/warning.svg";
import firebase from "../../../../services/firebaseConnection";
import Mensagens from "../../../../common/mensagens";
import Input from "../../../../components/Input";
import Textarea from "../../../../components/Textarea";

function FormBiotipo() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      height: "20vh",
      fontFamily: "Arial, Helvetica, sans-serif",
    },
    appbar: {
      background: "none",
    },
    appbarWrapper: {
      width: "75%",
      margin: "0 auto",
    },
    appbarTitle: {
      flexGrow: "1",
      color: "#FFF9EC",
      cursor: "pointer",
    },
    icon: {
      color: "var(--color-padrao)",
      fontSize: "2.5rem",
      cursor: "pointer",
    },
    homes: {
      minHeight: "90vh",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    title2: {
      flexGrow: "1",
      color: "#FFF9EC",
      textShadow: "#000 -1px -1px",
    },
    colorText: {
      color: "var(--color-padrao)",
    },
    container: {
      textAlign: "center",
    },
    titleHome: {
      color: "var(--color-padrao)",
      fontSize: "6rem",
      textShadow: "#000 -1px -1px",
      "@media(max-Width: 600px)": {
        fontSize: "4rem",
      },
    },
    goDown: {
      color: "var(--color-padrao)",
      fontSize: "3.5rem",
      backgroundColor: "#FFF",
      borderRadius: "50px",
    },
    home: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    ahome: {
      display: "block",
      marginBottom: "5px",
      color: "var(--color-padrao)",
      fontSize: "40px",
      textDecoration: "none",
      font: "Arial, Helvetica, sans-serif",
    },
  }));

  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [idade, setIdade] = useState("");
  const [redesocial, setRedesocial] = useState("");
  const [nascimento, setNascimento] = useState("");
  const [resposta01, setResposta01] = useState("");
  const [resposta02, setResposta02] = useState("");
  const [resposta03, setResposta03] = useState("");
  const [resposta04, setResposta04] = useState("");
  const [resposta05, setResposta05] = useState("");
  const [resposta06, setResposta06] = useState("");
  const [resposta07, setResposta07] = useState("");
  const [resposta08, setResposta08] = useState("");
  const [resposta09, setResposta09] = useState("");
  const [resposta10, setResposta10] = useState("");
  const [resposta11, setResposta11] = useState("");
  const [resposta12, setResposta12] = useState("");
  const [resposta13, setResposta13] = useState("");
  const [resposta14, setResposta14] = useState("");
  const [resposta15, setResposta15] = useState("");

  const pergunta1 = "1. Qual sua maior dificuldade em relação ao seu corpo? *";
  const pergunta2 = "2. Qual o número do seu manequim?";
  const pergunta3 = "3. Qual a sua altura? *";
  const pergunta4 = "4. Qual a sua proporção corporal (seu peso)? *";
  const pergunta5 = "5. O que deseja melhorar com a consultoria de morfologia corporal? *";
  const pergunta6 = "6. Qual parte do seu corpo você mais gosta? *";
  const pergunta7 = "7. Qual parte do seu corpo gostaria de disfarçar? *";
  const pergunta8 = "8. Tem algum ponto do seu corpo que dar maior destaque? *";
  const pergunta9 = "9. Que tipo de roupa geralmente te deixa mais feliz, mais confortável? *";
  const pergunta10 = "10. Qual o seu principal objetivo em relação a sua imagem pessoal? *";
  const pergunta11 = "11. Quais tipos de peças mais te agradam? - Ex: Saia, Short, calça jeans, calça alfaiataria, vestido curto, vestido longo, vestido justo, macacão, todos, nenhum, outros. *";
  const pergunta12 = "12. Que tipo de tecidos você mais gosta? Ex: Linho - Viscose - Malha - Seda - Jeans - Algodão - Couro ou outros *";
  const pergunta13 = "13. Já sabe seu estilo? Se sim, qual é? *";
  const pergunta14 = "14. Você tem preferência por alguma paleta de cores? Quais são? *";
  const pergunta15 = "15. Tem alguna história especial ou algo a mais sobre você que gostaria de me falar? *";
  const obs = "Obs.: Este é um assunto entre Consultora e Cliente! Tenha certeza de que suas informações são confidenciais e não serão compartilhadas.";

  function cadastrar(e) {
    e.preventDefault();

    if (
      nome === "" ||
      email === "" ||
      telefone === "" ||
      idade === "" ||
      redesocial === "" ||
      nascimento === ""
    ) {
      toastr.warning("Verifique", "Existe campos ainda não preenchido!");
      return;
    }

    firebase
      .firestore()
      .collection("questionariobiotipo")
      .add({
        nome: nome,
        email: email,
        telefone: telefone,
        idade: idade,
        nascimento: nascimento,
        redesocial: redesocial,
        resposta01: pergunta1 + " Resposta: " + resposta01,
        resposta02: pergunta2 + " Resposta: " + resposta02,
        resposta03: pergunta3 + " Resposta: " + resposta03,
        resposta04: pergunta4 + " Resposta: " + resposta04,
        resposta05: pergunta5 + " Resposta: " + resposta05,
        resposta06: pergunta6 + " Resposta: " + resposta06,
        resposta07: pergunta7 + " Resposta: " + resposta07,
        resposta08: pergunta8 + " Resposta: " + resposta08,
        resposta09: pergunta9 + " Resposta: " + resposta09,
        resposta10: pergunta10 + " Resposta: " + resposta10,
        resposta11: pergunta11 + " Resposta: " + resposta11,
        resposta12: pergunta12 + " Resposta: " + resposta12,
        resposta13: pergunta13 + " Resposta: " + resposta13,
        resposta14: pergunta14 + " Resposta: " + resposta14,
        resposta15: pergunta15 + " Resposta: " + resposta15,
        dataCadastro: new Date(),
      })
      .then(() => {
        setEmail("");
        setNome("");
        setTelefone("");
        setIdade("");
        setNascimento("");
        setRedesocial("");
        setResposta01("");
        setResposta02("");
        setResposta03("");
        setResposta04("");
        setResposta05("");
        setResposta06("");
        setResposta07("");
        setResposta08("");
        setResposta09("");
        setResposta10("");
        setResposta11("");
        setResposta12("");
        setResposta13("");
        setResposta14("");
        setResposta15("");
        toastr.success(
          "Sucesso",
          "Morfoologia Corpotal enviada com Sucesso. Aguarde o resultado do seu Biotipo!"
        );
      })
      .catch(() => {
        toastr.error("Erro", "Ocorreu um erro, tente novamente!");
      });
  }

  const classes = useStyles();
  const [checked] = useState(false);

  return (
    <>
      <div className={classes.root} id="header">
        <Collapse
          in={checked}
          {...(checked ? { timeout: 1000 } : {})}
          collapsedHeight={50}
        >
          <div className={classes.container}>
            <h1 className={classes.titleHome}>
              Morfologia <span className={classes.title2}> Corporal </span>
              <hr className="linha" />
            </h1>
          </div>
        </Collapse>
      </div>
      <div id="page-biotipo-form" className="container">
        <main className="padrao">
          <form onSubmit={cadastrar}>
            <fieldset>
              <div className="row">
                <legend>Identificação</legend>
              </div>

              <Input
                name="nome"
                label="Seu nome completo *"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
              />
              <Input
                name="email"
                type="email"
                label="E-mail *"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />

              <Input
                name="telefone"
                label="WhatsApp/Telefone *"
                placeholder="(00)00000-0000"
                value={telefone}
                onChange={(e) => {
                  setTelefone(e.target.value);
                }}
              />

              <Input
                name="redesocial"
                placeholder="seu_instagram"
                label="Instagram *"
                value={redesocial}
                onChange={(e) => {
                  setRedesocial(e.target.value);
                }}
              />

              <Input
                name="nascimento"
                label="Dia/Mês nascimento *"
                placeholder="__/__"
                value={nascimento}
                onChange={(e) => {
                  setNascimento(e.target.value);
                }}
              />
              <Input
                name="idade"
                label="Idade *"
                placeholder="__"
                value={idade}
                onChange={(e) => {
                  setIdade(e.target.value);
                }}
              />

              <div className="row" style={{ marginTop: 15 }}>
                <legend>
                  Responda o questionário de Morfologia Corporal abaixo
                </legend>
                <span
                  style={{
                    color: "#FFF",
                    backgroundColor: "var(--color-padrao)",
                    fontSize: 12,
                    padding: 5,
                    borderRadius: 4,
                    textAlign: "center",
                  }}
                >
                  {obs}
                </span>
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta1}</b>
                </span>
                <Input
                  name="resposta01"
                  value={resposta01}
                  onChange={(e) => {
                    setResposta01(e.target.value);
                  }}
                />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta2}</b>
                </span>
                <Input
                  name="resposta02"
                  value={resposta02}
                  onChange={(e) => {
                    setResposta02(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta3}</b>
                </span>
                <Input
                  name="resposta03"
                  value={resposta03}
                  onChange={(e) => {
                    setResposta03(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta4}</b>
                </span>
                <Input
                  name="resposta04"
                  value={resposta04}
                  onChange={(e) => {
                    setResposta04(e.target.value);
                  }}
                />
              </div>
              <div className="marginTopBottom">
                <span>
                  <b>{pergunta5}</b>
                </span>
                <Input
                  name="resposta05"
                  value={resposta05}
                  onChange={(e) => {
                    setResposta05(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta6}</b>
                </span>
                <Input
                  name="resposta06"
                  value={resposta06}
                  onChange={(e) => {
                    setResposta06(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta7}</b>
                </span>
                <Input
                  name="resposta07"
                  value={resposta07}
                  onChange={(e) => {
                    setResposta07(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta8}</b>
                </span>
                <Input
                  name="resposta08"
                  value={resposta08}
                  onChange={(e) => {
                    setResposta08(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta9}</b>
                </span>
                <Input
                  name="resposta09"
                  value={resposta09}
                  onChange={(e) => {
                    setResposta09(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta10}</b>
                </span>
                <Textarea
                  name="resposta10"
                  value={resposta10}
                  onChange={(e) => {
                    setResposta10(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta11}</b>
                </span>
                <Textarea
                  name="resposta11"
                  value={resposta11}
                  onChange={(e) => {
                    setResposta11(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta12}</b>
                </span>
                <Input
                  name="resposta12"
                  value={resposta12}
                  onChange={(e) => {
                    setResposta12(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta13}</b>
                </span>
                <Input
                  name="resposta13"
                  value={resposta13}
                  onChange={(e) => {
                    setResposta13(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta14}</b>
                </span>
                <Input
                  name="resposta14"
                  value={resposta14}
                  onChange={(e) => {
                    setResposta14(e.target.value);
                  }}
                />
              </div>

              <div className="marginTopBottom">
                <span>
                  <b>{pergunta15}</b>
                </span>
                <Input
                  name="resposta15"
                  value={resposta15}
                  onChange={(e) => {
                    setResposta15(e.target.value);
                  }}
                />
              </div>
            </fieldset>

            <footer>
              <p>
                <img src={warningIcon} alt="Aviso importante" />
                Importante! <br />
                Preencha todos os campos obrigatórios (*)
              </p>
              <button type="submit">Enviar</button>
            </footer>
          </form>
          <Mensagens />
        </main>
      </div>
    </>
  );
}

export default FormBiotipo;
