import React, {useState, useContext} from 'react';
import './login.css';
import logo from '../../assets/images/moda.svg';
import { AuthContext } from '../../contexts/auth'
import { toast } from 'react-toastify'
import Loader from "react-loader-spinner";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Login () {
  const [email, setEmail] = useState ('mayy@gmail.com');
  const [password, setPassword] = useState ('');

  const {logar, loadingAuth } = useContext(AuthContext)

  function handleSubmit (e) {
    e.preventDefault ();
    if(email !== '' && password !== ''){
      logar(email, password)
    } else {  
      toast.error('E-mail/Senha devem ser preenchidos!')
    }
  }

  return (
    <div className="container-center">
      <div className="login">
        <div className="login-area">
          <img src={logo} alt="Logo do Sistema" />
        </div>
        <form onSubmit={handleSubmit}>
          <h1>Acessar Sistema</h1>
          <input
            type="text"
            placeholder="Seu email"
            value={email}
            onChange={e => setEmail (e.target.value)}
          />
          <input
            type="password"
            placeholder="******"
            value={password}
            onChange={e => setPassword (e.target.value)}
          />
          <button type="submit">
            {loadingAuth ? (

              <Loader
                type="Puff"
                color="#FFF"
                height={25}
                width={25}
                timeout={3000} //3 secs
              />
            ) : 'Acessar'}
          </button>
        </form>
        {/* <Link to="registrar">Criar uma conta</Link> */}
      </div>
    </div>
  );
}

export default Login;
