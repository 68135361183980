import {useState, createContext, useEffect} from 'react';
import firebase from '../services/firebaseConnection';
import {toast} from 'react-toastify';

export const AuthContext = createContext ({});

function AuthProvider({children}) {
  const [user, setUser] = useState (null);
  const [loadingAuth, setLoadingAuth] = useState (false);
  const [loading, setLoading] = useState (true);

  useEffect (() => {
    function loadingStorage () {
      const storageUser = localStorage.getItem ('UseStylist');
      if (storageUser) {
        setUser (JSON.parse (storageUser));
        setLoading (false);
      }
      setLoading (false);
    }

    loadingStorage ();
  }, []);

  //Fazendo login
  async function logar (email, password) {
    setLoadingAuth (true);

    await firebase
      .auth ()
      .signInWithEmailAndPassword (email, password)
      .then (async value => {
        let uid = value.user.uid;

        const userProfile = await firebase
          .firestore ()
          .collection ('users')
          .doc (uid)
          .get ();

        let data = {
          uid: uid,
          nome: userProfile.data ().nome,
          avatarUrl: userProfile.data ().avatarUrl,
          email: value.user.email,
        };
        setUser (data);
        storageUser (data);
        setLoadingAuth (false);
        toast.success ('Bem vindo de volta!');
      })
      .catch (error => {
        console.log (error);
        if (error.code === 'auth/user-not-found' || 'auth/wrong-password') {
          toast.error ('Verifique, E-mail/Senha ou Usuário não cadastrado!');
          return null;
        }
        setLoadingAuth (false);
      });
  }

  //Cadastrando um novo usuário
  async function cadastrarUsuario (email, password, name) {
    setLoadingAuth (true);
    await firebase
      .auth ()
      .createUserWithEmailAndPassword (email, password)
      .then (async value => {
        let uid = value.user.uid;

        await firebase
          .firestore ()
          .collection ('users')
          .doc (uid)
          .set ({
            nome: name,
            avatarUrl: null,
          })
          .then (() => {
            let data = {
              uid: uid,
              nome: name,
              email: value.user.email,
              avatarUrl: null,
            };
            setUser (data);
            storageUser (data);
            setLoadingAuth (false);
            toast.success ('Bem vindo à Plataforma!');
          });
      })
      .catch (error => {
        console.log (error);
        if (error.code === 'auth/weak-password') {
          toast.error ('Senha deve ter no mínimo 6 caracteres!');
        }
        if (error.code === 'auth/email-already-in-use') {
          toast.error ('Usuário já cadastrado!');
        }
        setLoadingAuth (false);
      });
  }

  async function logout () {
    await firebase.auth ().signOut ();
    localStorage.removeItem ('UseStylist');
    setUser (null);
  }

  //Salvando no LocalStorage
  function storageUser (data) {
    localStorage.setItem ('UseStylist', JSON.stringify (data));
  }

  return (
    <AuthContext.Provider
      value={{
        logado: !!user,
        user,
        loading,
        cadastrarUsuario,
        logout,
        logar,
        loadingAuth,
        setUser,
        storageUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
