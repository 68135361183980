import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../../components/Header';
import Title from '../../../components/Title';
import { FiEdit } from 'react-icons/fi';
import firebase from '../../../services/firebaseConnection';
import { toast } from 'react-toastify';

function AlterarEmail() {
	//Pega o id para edição
	const { id } = useParams();
	const history = useHistory();
	const [email, setEmail] = useState('');

	//Carrega ao abrir a tela
	useEffect(
		() => {
			async function loadEmail() {
				await firebase
					.firestore()
					.collection('listaemails')
					.get()
					.then(snapshot => {
						let lista = [];

						snapshot.forEach(doc => {
							lista.push({
								id: doc.id
							});
						});

						//Editando dados
						if (id) {
							loadId(lista);
						}
					})
					.catch(error => {
						toast.error('Ops ocorreu um erro!');
					});
			}
			loadEmail()
		},
		[id]
	);

	async function loadId(lista) {
		//Buscando o E-mail pelo ID
		await firebase
			.firestore()
			.collection('listaemails')
			.doc(id)
			.get()
			.then(snapshot => {
				setEmail(snapshot.data().email);
			})
			.catch(error => {
				toast.error('Ops ocorreu durante o carregamento!');
			});
	}

	async function alterarEmail(e) {
		e.preventDefault();

		//Editando um e-mail
		await firebase.firestore().collection('listaemails').doc(id).update({
			email: email,
		})
			.then(() => {
				toast.success('E-mail atualizado com Sucesso!');
				history.push('/lista-emails')
			})
			.catch(error => {
				toast.error('Ops ocorreu um erro, tente novamente!');
			})
		return;
	}

	return (
		<div>
			<Header />
			<div className="content">
				<Title name="Editar e-mail">
					<FiEdit size={25} />
				</Title>
				<div className="container">
					<form className="form-profile" onSubmit={alterarEmail}>
						<div><label>E-mail</label></div>
						<input
							type="text"
							placeholder="..."
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
						<button type="submit">Salvar</button>
					</form >
				</div >
			</div >
		</div >
	);
}


export default AlterarEmail