import './new.css';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiEdit } from 'react-icons/fi';
import firebase from '../../services/firebaseConnection';
import { toast } from 'react-toastify';
import SelectButton from '../../components/SelectButton/index';

function AlteraAgendamento() {
	//Pega o id para edição
	const { id } = useParams();
	const history = useHistory();

	const [nome, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [telefone, setTelefone] = useState('');
	const [mensagem, setMensagem] = useState('');
	const [tipoEncontro, setTipoEncontro] = useState('');
	const [valor, setValor] = useState(0);
	const [formaPagamento, setFormaPagamento] = useState('');
	const [qtdParcelas, setQtdParcelas] = useState(0);
	const [status, setStatus] = useState('Em andamento');

	//Carrega ao abrir a tela
	useEffect(
		() => {
			async function loadAgendamentos() {
				await firebase
					.firestore()
					.collection('agendamentos')
					.get()
					.then(snapshot => {
						let lista = [];

						snapshot.forEach(doc => {
							lista.push({
								id: doc.id
							});
						});

						//Editando dados
						if (id) {
							loadId(lista);
						}
					})
					.catch(error => {
						toast.error('Ops ocorreu um erro!');
					});
			}
			loadAgendamentos()
		},
		[id]
	);

	async function loadId(lista) {
		//Buscando o Cliente pelo ID
		await firebase
			.firestore()
			.collection('agendamentos')
			.doc(id)
			.get()
			.then(snapshot => {
				setNome(snapshot.data().nome);
				setEmail(snapshot.data().email);
				setTelefone(snapshot.data().telefone);
				setMensagem(snapshot.data().mensagem);
				setTipoEncontro(snapshot.data().tipoEncontro);
				setValor(snapshot.data().valor);
				setFormaPagamento(snapshot.data().formaPagamento);
				setStatus(snapshot.data().status);
				setQtdParcelas(snapshot.data().qtdParcelas)
			})
			.catch(error => {
				toast.error('Ops ocorreu durante o carregamento!');
			});
	}

	async function editarAcompanhamento(e) {
		e.preventDefault();

		//Editando um agendamento
		await firebase.firestore().collection('agendamentos').doc(id).update({
			nome: nome,
			email: email,
			telefone: telefone,
			status: status,
			mensagem: mensagem,
			tipoEncontro: tipoEncontro,
			valor: valor,
			formaPagamento: formaPagamento,
			qtdParcelas: qtdParcelas

		})
			.then(() => {
				toast.success('Atualizado com Sucesso!');
				history.push('/acompanhamento')
			})
			.catch(error => {
				toast.error('Ops ocorreu um erro, tente novamente!');
			})

		return;
	}

	function mudaStatus(e) {
		setStatus(e.target.value);
	}

	return (
		<div>
			<Header />
			<div className="content">
				<Title name="Editar acompanhamento">
					<FiEdit size={25} />
				</Title>
				<div className="container">
					<form className="form-profile" onSubmit={editarAcompanhamento}>
						<div className="formulario">
						</div>
						<div><label>Nome</label></div>
						<input
							type="text"
							placeholder="..."
							value={nome}
							onChange={e => setNome(e.target.value)}
						/>
						<div><label>E-mail</label></div>
						<input
							type="text"
							placeholder="..."
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
						<div><label>Telefone</label></div>
						<input
							type="text"
							placeholder="..."
							value={telefone}
							onChange={e => setTelefone(e.target.value)}
						/>
						<div><label>Mensagem</label></div>
						<textarea
							type="text"
							placeholder="..."
							value={mensagem}
							onChange={e => setMensagem(e.target.value)}
						/>
						<div><label>Tipo de encontro</label></div>
						<input
							type="text"
							placeholder="..."
							value={tipoEncontro}
							onChange={e => setTipoEncontro(e.target.value)}
						/>
						<div><label>Valor</label></div>
						<input
							type="number"
							placeholder="R$ 0,00"
							value={valor}
							onChange={e => setValor(e.target.value)}
						/>
						<div><label>Quantidade de Parcelas</label></div>
						<input
							type="number"
							placeholder="0,00"
							value={qtdParcelas}
							onChange={e => setQtdParcelas(e.target.value)}
						/>
						<div>
							<div><label>Forma de pagamento</label></div>
							<SelectButton
								name="formaPagamento"
								value={formaPagamento}
								onChange={e => { setFormaPagamento(e.target.value) }}
								options={[
									{ value: 'Crédito', label: 'Crédito' },
									{ value: 'Débito', label: 'Débito' },
									{ value: 'Débito/Pix', label: 'Débito/Pix' },
									{ value: 'Pix', label: 'Pix' },
									{ value: 'Link de Pagamento', label: 'Link de Pagamento' },
									{ value: 'Dinheiro', label: 'Dinheiro' },
									{ value: 'Boleto à Vista', label: 'Boleto à Vista' },
								]}
							/>
						</div>

						<label>Status da Consultoria</label>
						<div className="status">
							<input
								id='andamento'
								type="radio"
								name="radio"
								value="Em andamento"
								checked={status === 'Em andamento'}
								onChange={mudaStatus}
							/>
							<label htmlFor='andamento'>Em andamento</label>
							<input
								id='finalizado'
								type="radio"
								name="radio"
								value="Finalizado"
								checked={status === 'Finalizado'}
								onChange={mudaStatus}
							/>
							<label htmlFor='finalizado'>Finalizado</label>

						</div>
						<br />
						<br />
						<button type="submit">Salvar</button>
						<br />
					</form >
				</div >
			</div >
		</div >
	);
}


export default AlteraAgendamento