import './styles.css'
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Collapse } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr'
import Input from '../../components/Input';
import warningIcon from '../../assets/icons/warning.svg';
import Select from '../../components/Select/index';

import firebase from '../../services/firebaseConnection'
import Mensagens from '../../common/mensagens';
import Textarea from '../../components/Textarea';

function Cadastro() {
    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            height: '50vh',
            fontFamily: 'Arial, Helvetica, sans-serif',
        },
        appbar: {
            background: 'none',
        },
        appbarWrapper: {
            width: '75%',
            margin: '0 auto',
        },
        appbarTitle: {
            flexGrow: '1',
            color: '#FFF9EC',
            cursor: 'pointer'
        },
        icon: {
            color: 'var(--color-padrao)',
            fontSize: '2.5rem',
            cursor: 'pointer'
        },
        homes: {
            minHeight: '90vh',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',

        },
        title2: {
            flexGrow: '1',
            color: '#FFF9EC',
            textShadow: '#000 -1px -1px',
        },
        colorText: {
            color: 'var(--color-padrao)'
        },
        container: {
            textAlign: 'center',
        },
        titleHome: {
            color: 'var(--color-padrao)',
            fontSize: '7rem',
            textShadow: '#000 -1px -1px',
            '@media(max-Width: 600px)': {
                fontSize: '4rem'
            }
        },
        goDown: {
            color: 'var(--color-padrao)',
            fontSize: '3.5rem',
            backgroundColor: '#FFF',
            borderRadius: '50px'
        },
        home: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        ahome: {
            display: 'block',
            marginBottom: '5px',
            color: 'var(--color-padrao)',
            fontSize: '40px',
            textDecoration: 'none',
            font: 'Arial, Helvetica, sans-serif'
        }
    }));

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [tipoEncontro, setTipoEncontro] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [status, setStatus] = useState('Pré cadastro');

    function agendar(e) {
        e.preventDefault()

        if (name === '' || email === '' || mensagem === '' || tipoEncontro === ''
        ) {
            toastr.warning('Verifique', 'Nome, E-mail, Mensagem e o tipo do Encontro deve ser preenchido!');
            return
        }

        firebase.firestore().collection('agendamentos').add({
            nome: name,
            email: email,
            telefone: telefone,
            mensagem: mensagem,
            tipoEncontro: tipoEncontro,
            valor: 0,
            qtdParcelas: 0,
            formaPagamento: 0,
            status: status,

            dataCadastro: new Date(),
        }).then(() => {
            setName('')
            setTelefone('')
            setEmail('')
            setMensagem('')
            setStatus('')
            setTipoEncontro('')
            toastr.success('Sucesso', 'Mensagem enviada com Sucesso. Entraremos em contato!')
        }).catch(() => {
            toastr.error('Erro', 'Ocorreu um erro, tente novamente!');
        })
    }

    const classes = useStyles();
    const [checked] = useState(false);

    return (
        <>
            <div className={classes.root} id="header">
                <Collapse
                    in={checked}
                    {...(checked ? { timeout: 1000 } : {})}
                    collapsedHeight={50}
                >
                    <div className={classes.container}>
                        <h1 className={classes.titleHome}>
                            Vamos agendar <span className={classes.title2}> um café</span><br /><span className={classes.colorText}>Online/Presencial</span>
                            <hr className='linha'/>

                        </h1>
                    </div>
                </Collapse>
            </div>
            <div id="page-teacher-form" className="container">
                <main className='padrao'>
                    <form onSubmit={agendar}>
                        <fieldset>

                            <div className="row">
                                <legend>Entre em contato comigo</legend>
                            </div>
                            <Input
                                name="name "
                                label="Seu nome completo *"
                                value={name}
                                onChange={(e) => { setName(e.target.value) }}
                            />
                            <Input
                                name="email"
                                type='email'
                                label="E-mail *"
                                value={email}
                                onChange={(e) => { setEmail(e.target.value) }}
                            />
                            <Input
                                name="telefone"
                                label="WhatsApp/Telefone"
                                value={telefone}
                                onChange={(e) => { setTelefone(e.target.value) }}
                            />
                            <Textarea
                                name="mensagem"
                                label="Descreva aqui o seu interesse *"
                                value={mensagem}
                                onChange={(e) => { setMensagem(e.target.value) }}
                            />
                            <Select
                                name="tipoEncontro"
                                label="Qual o tipo de Encontro *"
                                value={tipoEncontro}
                                onChange={e => setTipoEncontro(e.target.value)}
                                options={[
                                    { value: 'Presencial', label: 'PRESENCIAL - BRASÍLIA E ENTORNO' },
                                    { value: 'On-Line', label: 'ON-LINE' },
                                ]}
                            />

                        </fieldset>
                        <footer>
                            <p>
                                <img src={warningIcon} alt="Aviso importante" />
                                Importante! <br />
                                Preencha todos os campos obrigatórios (*)
                            </p>
                            <button type="submit">
                                Enviar
                            </button>
                        </footer>
                    </form>
                    <Mensagens />
                </main>
            </div >
        </>
    )
}

export default Cadastro
