import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { FiCreditCard, FiLayers, FiUserCheck } from 'react-icons/fi';
import { toast } from 'react-toastify';
import firebase from '../../services/firebaseConnection'
import './relatorios.css'

const listRef = firebase.firestore().collection('agendamentos').where('status', '==', 'Finalizado');

export default function Relatorios() {

  const [total, setTotal] = useState(0);
  const [cliente, setClientes] = useState(0);


  useEffect(() => {
    function loadChamados() {

      let totalSoma = 0
      let totalClientes = 0

      listRef.get()
        .then((snapshot) => {
          snapshot.forEach(doc => {
            let total = parseFloat(doc.data().valor);
            totalSoma += total;
            totalClientes++;
          })
          setTotal(totalSoma);
          setClientes(totalClientes);
        })

        .catch(() => {
          toast.error('Ops ocorreu algum erro')
        })

    }

    loadChamados();

    return () => {

    }
  }, [])


  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Relatorios">
          <FiLayers size={25} />
        </Title>
        <div className="relatorio">
          <div className="box">
            <div className="small-box" >
              <div className="inner col-2">
                <h2>Total geral</h2>
                <p>{total}</p>
              </div>
              <div className="icon col-2">
                <h1>
                  <FiCreditCard size={25} />
                </h1>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="small-box" >
              <div className="inner col-2">
                <h2>Clientes</h2>
                <p>{cliente}</p>
              </div>
              <div className="icon col-2">
                <h1>
                  <FiUserCheck size={25} />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
