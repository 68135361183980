import { useContext } from 'react';
import Loader from 'react-loader-spinner';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/auth';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const { logado, loading } = useContext(AuthContext);

  if (loading) {
    return <div className='loading'>
    <Loader
      type="Puff"
      color="#FFF"
      height={25}
      width={25}
      timeout={3000} //3 secs
    /></div>;
  }

  if (!logado && isPrivate) {
    return <Redirect to="/adm" />;
  }

  if (logado && !isPrivate) {
    return <Redirect to="/pre-cadastro" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
}
