import "./style.css";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Collapse } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Link as Scroll } from "react-scroll";
import { Instagram, LinkedIn, Facebook, Email, Call } from "@material-ui/icons";
import firebase from "../../services/firebaseConnection";
import Input from "../../components/Input";

import Sobre from "../Sobre";
import Agenda from "../Cadastro";
import ImgRegina from "../../assets/images/regina.png";
import ImgIngrid from "../../assets/images/ingrid.png";
import ImgIGil from "../../assets/images/gildeane.png";
import ImgLay from "../../assets/images/layane.png";
import ImgIAdriana from "../../assets/images/adriana.png";
import Luanne from "../../assets/images/luanne.png";

import AnaliseCapilarIris from "./img/novas/analise_capilar_iris.jpeg";
import AnaliseEstiloExpress from "./img/novas/analise_estilo_express.jpeg";
import AnaliseIris from "./img/novas/analise_iris.jpeg";
import BrandingProfissional from "./img/novas/branding_profissional.jpeg";
import ColoracaoDigital from "./img/novas/coloracao_digital.jpeg";
import ColoracaoPessoal from "./img/novas/coloracao_pessoal.jpeg";
import ConsultoriaEstiloEmpresa from "./img/novas/consultoria_estilo_empresa.jpeg";
import ConsultoriaEstiloIndividual from "./img/novas/consultoria_estilo_individual.jpeg";
import consultoriaImagemCompleta from "./img/novas/consultoria_imagem_completa.jpeg";
import ConsultoriaNoivas from "./img/novas/consultoria_noivas.jpeg";
import ConsultoriaOptica from "./img/novas/consultoria_optica.jpeg";
import GuardaroupaEstrategico from "./img/novas/guardaroupa_estrategico.jpeg";
import MalaEstrategica from "./img/novas/mala_estrategica.jpeg";
import MapeamentoFacial from "./img/novas/mapeamento_facial.jpeg";
import MontagemLooks from "./img/novas/montagem_looks.jpeg";
import MorfologiaCorporal from "./img/novas/morfologia_corporal.jpeg";
import PalestraWorkshop from "./img/novas/palestra_workshop.jpeg";
import PersonalShopper from "./img/novas/personal_shopper.jpeg";
import Visagismo from "./img/novas/visagismo.jpeg";
import ColorDayGrupo from "./img/novas/color_day_group.jpeg";

import Img1 from "./img/1.svg";
import Img2 from "./img/2.svg";
import Img3 from "./img/3.svg";
import Img4 from "./img/4.svg";
import Img5 from "./img/5.svg";
import Img6 from "./img/6.svg";
import Img7 from "./img/7.svg";
import Img8 from "./img/8.svg";
import Img9 from "./img/9.svg";
import Img10 from "./img/10.svg";
import Img11 from "./img/11.svg";
import Img12 from "./img/12.svg";

import { toastr } from "react-redux-toastr";
import Mensagens from "../../common/mensagens";
import Home from "../Home";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100%",
    fontFamily: "Arial, Helvetica, sans-serif",
  },
  title2: {
    flexGrow: "1",
    color: "#FFF9EC",
    textShadow: "#000 -1px -1px",
  },
  icon: {
    color: "var(--color-padrao)",
    fontSize: "3rem",
    cursor: "pointer",
  },
  colorText: {
    color: "var(--color-padrao)",
    textShadow: "#000 -1px -1px",
  },
  titleHome: {
    color: "var(--color-padrao)",
    fontSize: "7rem",
    textAlign: "center",
    textShadow: "#000 -1px -1px",
    "@media(max-Width: 600px)": {
      fontSize: "4rem",
    },
  },
  menubar: {
    color: "var(--color-padrao)",
    fontSize: "1.6rem",
    backgroundColor: "#FFF",
    borderRadius: "5px",
    padding: "3px",
  },
  goDown: {
    color: "var(--color-padrao)",
    fontSize: "3.5rem",
    backgroundColor: "#FFF",
    borderRadius: "50px",
  },
  depoimentos: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "30vh",
    fontFamily: "Arial, Helvetica, sans-serif",
  },
}));
export default function Header() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setChecked(true);

    let count = 1;
    document.getElementById("radio1").checked = true;

    setInterval(function () {
      nextImagem();
    }, 7000);

    function nextImagem() {
      count++;
      if (count > 12) {
        count = 1;
      }

      document.getElementById("radio" + count).checked = true;
    }
  }, []);

  function enviarEmail(e) {
    e.preventDefault();

    if (email === "") {
      toastr.warning("Verifique", "Informe seu e-mail.");
      return;
    }

    firebase
      .firestore()
      .collection("listaemails")
      .add({
        email: email,
        check: false,
        bonus: false,
      })
      .then(() => {
        setEmail("");
        toastr.success(
          "Sucesso",
          "E-mail enviado com Sucesso. Você receberá nossas dicas de moda e muito mais..!"
        );
      })
      .catch(() => {
        toastr.error("Erro", "Ocorreu um erro, tente novamente!");
      });
  }

  return (
    <>
      <Home />

      <div className={classes.root}>
        <div className={classes.container}>
          <h1 className={classes.titleHome}>
            Consultoria{" "}
            <span className={classes.title2}> de Imagem e Estilo</span>
            <br />
            <span className={classes.title2}>Personalizada</span>
            <span className={classes.colorText}> para você</span>
            <br />
{/*             <span className="texto-boas-vindas">
              Seja muito bem vinda ao seu site de estilo. Minha missão aqui é
              desenhar e executar sua estratégia de imagem pessoal e
              profissional, alinhando os nossos serviços com o seu desejo de
              imagem, trazendo autenticidade, empoderamento e resultados
              incríveis para você. TODOS os serviços podem ser feitos de forma
              ONLINE ou Presencial. Em Todos eles será preparado um Dossiê
              Personalizado e completo dentro de tudo o que formos trabalhar em
              relação a sua imagem pessoal, coloração, biótipo, etc. Você ainda
              terá um Portifólio online onde poderá consultar e se inspirar toda
              vez que precisar.
            </span> */}
          </h1>
          <Scroll to="home" smooth={true} className="pulse">
            <IconButton>
              <ExpandMoreIcon className={classes.goDown} />
            </IconButton>
          </Scroll>
        </div>
      </div>

      <Mensagens />
      
      <div id="home">
        <div className="row-card">
          <a href="https://pay.kiwify.com.br/snFzroD"
            title="Análise Capilar e Íris"
          >
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={AnaliseCapilarIris}
                alt="Análise Capilar e Íris"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/BkeD6Oo" title="Análise de Íris">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={AnaliseIris}
                alt="Análise de Íris"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/Y8abXqr"
            title="Análise de Estilo Express"
          >
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={AnaliseEstiloExpress}
                alt="Análise de Estilo Express"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/F7h986b" title="Guarda-roupa Estratégico">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={GuardaroupaEstrategico}
                alt="Guarda-roupa Estratégico"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/6LTv9QQ" title="Coloração Pessoal">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={ColoracaoPessoal}
                alt="Coloração Pessoal"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/5QuNHZA" title="Coloração Digital">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={ColoracaoDigital}
                alt="Coloração Digital"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/lQ5h2xa" title="Consultoria Profissional">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={BrandingProfissional}
                alt="Consultoria Profissional"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/vMOh8Gi" title="Consultoria Óptica">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={ConsultoriaOptica}
                alt="Consultoria Óptica"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/Egc0Qd2" title="Consultoria Completa">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={consultoriaImagemCompleta}
                alt="Consultoria Completa"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/umJn46h"
            title="Consultoria de Estilo para Empresas"
          >
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={ConsultoriaEstiloEmpresa}
                alt="Consultoria de Estilo para Empresas"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/M3qOHsu"
            title="Consultoria para Noivas"
          >
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={ConsultoriaNoivas}
                alt="Consultoria para Noivas"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/Egc0Qd2" title="Consultoria de Estilo Individual">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={ConsultoriaEstiloIndividual}
                alt="Consultoria de Estilo Individual"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/ELJZhAu" title="Mala de Viagem Estratégica">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={MalaEstrategica}
                alt="Mala de Viagem Estratégica"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/EVEMU3K" title="Montagem de Looks">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={MontagemLooks}
                alt="Montagem de Looks"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/jBvb34O" title="Morfologia Corporal">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={MorfologiaCorporal}
                alt="Morfologia Corporal"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/P4tUVBM" title="Mapeamento Facial">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={MapeamentoFacial}
                alt="Mapeamento Facial"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/ECjRB0y" title="Palestras e Workshops">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={PalestraWorkshop}
                alt="Palestras e Workshops"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/qlgaCs9">
            <div className="card-consultoria padrao" title="Personal Shopper">
              <img
                className="image-logo-consultoria"
                src={PersonalShopper}
                alt="Personal Shopper"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/I6V2b3q" title="Visagismo">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={Visagismo}
                alt="Visagismo"
              />
            </div>
          </a>
          <a href="https://pay.kiwify.com.br/l6cuu6D" title="Color Day em Grupo">
            <div className="card-consultoria padrao">
              <img
                className="image-logo-consultoria"
                src={ColorDayGrupo}
                alt="Color Day em Grupo"
              />
            </div>
          </a>
        </div>
      </div>
      <div>
        <div id="sobre">
          <div className="cards">
            <Sobre />
          </div>
        </div>
        <div id="agendamento">
          <div className="cards">
            <Agenda />
          </div>
        </div>

        <div className={classes.depoimentos} id="depoimentos">
          <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
            collapsedHeight={50}
          >
            <div className={classes.container}>
              <h1 className={classes.titleHome}>
                Depoimentos
                <hr className="linha" />
              </h1>
            </div>
          </Collapse>
        </div>

        <div className="row-depoimento">
          <div className="card-depoimentos padrao">
            <img className="image" src={ImgRegina} alt="Depoimentos" />
            <h2>Regina Sofia</h2>
            <p className="regiao">Salvador/BA</p>
            <p>
              "Graças a Total dedicação e comprometimento da minha Consultora de
              Estilo (Mayy Machado) que hoje tenho segurança em me produzir sem
              medo de errar. Além da auto estima e auto conhecimento, todo
              cuidado que ela nos ensina a ter conosco mesma. Só tenho a
              agradecer pela transformação."💗🙏
            </p>
          </div>
          <div className="card-depoimentos padrao">
            <img className="image" src={ImgIAdriana} alt="Depoimentos" />
            <h2>Adriana Franco</h2>
            <p className="regiao">Brasília/DF</p>
            <p>
              "Muitas me peguei olhando para o guarda roupa e pensando "não ter
              nada para vestir". Mas a Mayy ela te pega realmente pela sua mão,
              com todo cuidado e te ensina o passo a passo para sua nova
              identidade visual. E posso te afirmar - é TRANSFORMADOR! Gratidão
              imensa!"
            </p>
          </div>

          <div className="card-depoimentos padrao">
            <img className="image" src={ImgIGil} alt="Depoimentos" />
            <h2>Gildeane Costa</h2>
            <p className="regiao">Belo Horizonte/MG</p>
            <p>
              "Ah a Mayy trouxe renovo para o meu visual. Aprendi tanto com ela
              em relação à muitos assuntos, não somente sobre Moda, mas sobre a
              importância que somos como ser humano. Além de uma Acessoria
              exclusiva para eu participar de um competitivo processo seletivo,
              onde com a ajuda dela p/ com meu visual eu pude ser a escolhida
              para o cargo que tanto queria. Gratidão."
            </p>
          </div>

          <div className="card-depoimentos padrao">
            <img className="image" src={ImgLay} alt="Depoimentos" />
            <h2>Layanny Melo</h2>
            <p className="regiao">Brasília/DF</p>
            <p>
              "Em apenas 15 minutos dentro de uma loja com essa pessoa incrível,
              a Mayy, pude já mudar meu ponto de vista em relação a moda, as
              cores, e técnicas preciosas de styling. Jamais usaria um Blazer
              lilás por exemplo, apenas bebe e neutros. Mas com a acessória dela
              pude melhorar muito minha relação e opinião sobre as cores e a
              moda em si."
            </p>
          </div>
          <div className="card-depoimentos padrao">
            <img className="image" src={ImgIngrid} alt="Depoimentos" />
            <h2>Ingrid</h2>
            <p className="regiao">Campo Grande/MS</p>
            <p>
              "A Consultoria de Estilo da Mayy faz refletir o nosso melhor de
              dentro pra fora... É como uma "Terapia de Estilo" de mesmo, bem
              pessoal, super Personalizado, onde nos vemos crescendo num
              processo incrível, como pessoa, como profissional, e como o ser
              humano especial que somos. É um Cuidado todo Especial dela para
              conosco. Ameeii tudo."
            </p>
          </div>
          <div className="card-depoimentos padrao">
            <img className="image" src={Luanne} alt="Depoimentos" />
            <h2>Luanne Salvino</h2>
            <p className="regiao">São Paulo/SP</p>
            <p>
              "Eu não imaginava o impacto que uma consultoria e que as cores
              podem fazer de dentro para fora. Eu não fazia ideia do quão
              profundo é o estudo da nossa coloração pessoal. Não foi uma
              consultoria, foi uma transformação. Contratem a Mayy Stylist. Foi
              extraordinário!!!"
            </p>
          </div>
        </div>

        <div className={classes.depoimentos} id="slider">
          <Collapse
            in={checked}
            {...(checked ? { timeout: 1000 } : {})}
            collapsedHeight={50}
          >
            <div className={classes.container}>
              <h1 className={classes.titleHome}>
                Dicas de Estilo
                <hr className="linha" />
              </h1>
            </div>
          </Collapse>
        </div>

        <div className="slider">
          <div className="slides">
            <input type="radio" name="radio-btn" id="radio1" />
            <input type="radio" name="radio-btn" id="radio2" />
            <input type="radio" name="radio-btn" id="radio3" />
            <input type="radio" name="radio-btn" id="radio4" />
            <input type="radio" name="radio-btn" id="radio5" />
            <input type="radio" name="radio-btn" id="radio6" />
            <input type="radio" name="radio-btn" id="radio7" />
            <input type="radio" name="radio-btn" id="radio8" />
            <input type="radio" name="radio-btn" id="radio9" />
            <input type="radio" name="radio-btn" id="radio10" />
            <input type="radio" name="radio-btn" id="radio11" />
            <input type="radio" name="radio-btn" id="radio12" />
            <div className="slide primeira">
              <img src={Img1} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img2} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img3} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img4} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img5} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img6} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img7} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img8} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img9} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img10} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img11} alt="Dicas de Moda" />
            </div>
            <div className="slide">
              <img src={Img12} alt="Dicas de Moda" />
            </div>
            <div className="navegacao-auto">
              <div className="auto-btn1"></div>
              <div className="auto-btn2"></div>
              <div className="auto-btn3"></div>
              <div className="auto-btn4"></div>
              <div className="auto-btn5"></div>
              <div className="auto-btn6"></div>
              <div className="auto-btn7"></div>
              <div className="auto-btn8"></div>
              <div className="auto-btn9"></div>
              <div className="auto-btn10"></div>
              <div className="auto-btn11"></div>
              <div className="auto-btn12"></div>
            </div>
          </div>
          <div className="navegacao-manual">
            <label htmlFor="radio1" className="manual-btn"></label>
            <label htmlFor="radio2" className="manual-btn"></label>
            <label htmlFor="radio3" className="manual-btn"></label>
            <label htmlFor="radio4" className="manual-btn"></label>
            <label htmlFor="radio5" className="manual-btn"></label>
            <label htmlFor="radio6" className="manual-btn"></label>
            <label htmlFor="radio7" className="manual-btn"></label>
            <label htmlFor="radio8" className="manual-btn"></label>
            <label htmlFor="radio9" className="manual-btn"></label>
            <label htmlFor="radio10" className="manual-btn"></label>
            <label htmlFor="radio11" className="manual-btn"></label>
            <label htmlFor="radio12" className="manual-btn"></label>
          </div>
        </div>
      </div>

      <div className={classes.depoimentos} id="contato">
        <Collapse
          in={checked}
          {...(checked ? { timeout: 1000 } : {})}
          collapsedHeight={50}
        >
          <div className={classes.container}>
            <h1 className={classes.titleHome}>
              Nossos contatos
              <hr className="linha" />
            </h1>
          </div>
        </Collapse>
      </div>

      <div id="page-email" className="container">
        <main className="padrao">
          <form onSubmit={enviarEmail}>
            <fieldset>
              <div className="row">
                <legend>Receba dicas e novidades de Moda e Estilo.</legend>
              </div>
              <Input
                name="email"
                type="email"
                label="Seu e-mail*"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </fieldset>
            <footer>
              <button type="submit">Enviar</button>
            </footer>
          </form>
        </main>
      </div>
      <a
        className="whatsapp-link pulse"
        href="https://api.whatsapp.com/send?phone=5561998894670&text=Olá estilosa, seja muito bem vinda...🤩✨ Me conte, no que posso te ajudar?.."
        target="blank"
        rel="noreferrer"
      >
        <i className="fa fa-whatsapp"></i>
      </a>
      <div className="footer" id="contato">
        <div className="footer-menu">
          <h2>Redes sociais / Contatos</h2>
          <div>
            <IconButton>
              <a
                href="https://www.instagram.com/mayy.stylist/"
                title="Instagram"
                target="blank"
                rel="noreferrer"
              >
                <Instagram className={classes.icon} />
              </a>
            </IconButton>
            <IconButton>
              <a
                href="https://m.facebook.com/mayy.stylist"
                title="Facebook"
                target="blank"
                rel="noreferrer"
              >
                <Facebook className={classes.icon} />
              </a>
            </IconButton>
            <IconButton>
              <a
                href="https://www.linkedin.com/in/mayystylist/"
                title="Linkedin"
                target="blank"
                rel="noreferrer"
              >
                <LinkedIn className={classes.icon} />
              </a>
            </IconButton>
            <IconButton>
              <a
                href="mailto:mayystylist@gmail.com"
                title="E-mail"
                target="blank"
                rel="noreferrer"
              >
                <Email className={classes.icon} />
              </a>
            </IconButton>
            <IconButton>
              <a
                href="https://api.whatsapp.com/send?phone=5561998894670&text=Olá estilosa, seja muito bem vinda...🤩✨ Me conte, no que posso te ajudar?.."
                title="Whatsapp"
                target="blank"
                rel="noreferrer"
              >
                <Call className={classes.icon} />
              </a>
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
}
