import React, { useState, useEffect } from "react";
import { FiMessageSquare, FiEye, FiCalendar, FiSlack } from "react-icons/fi";
import { Link } from "react-router-dom";
import firebase from "../../../../services/firebaseConnection";
import { toast } from "react-toastify";
import { format } from "date-fns";
import "./lista.css";
import Loader from "react-loader-spinner";
import Header from "../../../../components/Header";
import Title from "../../../../components/Title";
import Modal from "../../../../components/ModalAgenda";
const listRef = firebase.firestore().collection("questionariocores");

function ListaCores() {
  const [cadastro, setCadastro] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [lastDocs, setLastDocs] = useState();

  const [showPostModal, setShowPostModal] = useState(false);
  const [detail, setDetail] = useState();

  useEffect(() => {
    async function loadCores() {
      await listRef.limit(5).get().then((snapshot) => {
          updateState(snapshot);
        })
        .catch(() => {
          toast.error("Ops ocorreu algum erro");
          setLoadingMore(false);
        });

      setLoading(false);
    }

    loadCores();

    return () => {};
  }, []);

  async function updateState(snapshot) {
    const isCollectionEmpyty = snapshot.size === 0;

    if (!isCollectionEmpyty) {
      let lista = [];
      snapshot.forEach((doc) => {
        lista.push({
          id: doc.id,
          nome: doc.data().nome,
          email: doc.data().email,
          telefone: doc.data().telefone,
          mensagem: doc.data().mensagem,
          dataCadastro: doc.data().dataCadastro,
          dataFormatada: format(doc.data().dataCadastro.toDate(), "dd/MM/yyyy"),
        });
      });

      const lastDoc = snapshot.docs[snapshot.docs.length - 1]; //ultimo documento da lista
      setCadastro((cadastro) => [...cadastro, ...lista]);
      setLastDocs(lastDoc);
    } else {
      setIsEmpty(true);
    }

    setLoadingMore(false);
  }

  async function buscarMais() {
    setLoadingMore(true);
    await listRef
      .startAfter(lastDocs)
      .limit(5)
      .get()
      .then((snapshot) => {
        updateState(snapshot);
      });
  }

  function carregaModal(item) {
    //Chama modal - Trocando true - false
    setShowPostModal(!showPostModal);

    setDetail(item);
  }

  if (loading) {
    return (
      <div>
        <Header />
        <div className="content">
          <Title name="Novos questionários de Cores">
            <FiSlack size={25} />
          </Title>

          <div className="container dashboard">
            <span>Buscando questionários...</span>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Header />
      <div className="content">
        <Title name="Novos questionários de Cores">
          <FiSlack size={25} />
        </Title>

        {cadastro.length === 0 ? (
          <div className="container dashboard">
            <span>Nenhum questionário registrado</span>
          </div>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th scope="col">Cliente</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Telefone</th>
                  <th scope="col">Cadastrado em</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {cadastro.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="Cliente">{item.nome}</td>
                      <td data-label="E-mail">{item.email}</td>
                      <td data-label="Telefone">{item.telefone}</td>
                      <td data-label="Cadastrado">{item.dataFormatada}</td>
                      <td data-label="Acao">
                        <Link
                          to={`/editar-cores/${item.id}`}
                          className="action"
                          style={{ backgroundColor: "#3583f3" }}
                        >
                          <FiEye color="#FFF" size={17} />
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {loadingMore && (
              <h3 style={{ textAlign: "center", marginTop: 15 }}>
                <Loader
                  type="Puff"
                  color="#e83e8c"
                  height={80}
                  width={80}
                  timeout={3000} //3 secs
                />
              </h3>
            )}
            {!loadingMore && !isEmpty && (
              <button className="btn-more" onClick={buscarMais}>
                Buscar mais
              </button>
            )}
          </>
        )}
      </div>
      {showPostModal && <Modal conteudo={detail} close={carregaModal} />}
    </div>
  );
}

export default ListaCores;
